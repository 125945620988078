<section class="legal">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="title mt-4 mt-lg-0"><span>Aviso legal</span></h2>
                <div class="mb-4 text-center"><img src="assets/img/cg-separator.png" alt=""></div>
                <div class="card-shadow">
                    <!--
<h3><strong>Datos del Responsable</strong></h3>
<ul>
    <li><strong>Identidad del Responsable:</strong> Federación Galega de
        Atletismo</li>
    <li><strong>NIF/CIF:</strong> G15103500</li>
    <li><strong>Dirección:</strong> Avda. Glasgow, 13 15008 A
        Coruña</li>
    <li><strong>Correo electrónico:</strong>
        info@carreirasgalegas.com</li>
</ul>
<p>En este espacio, el usuario, podrá encontrar toda la información relativa a los
    términos y condiciones legales que definen las relaciones entre los usuarios y yo como responsable de esta web.
    Como usuario, es importante que conozcas estos términos antes de continuar tu navegación.</p>
<p>Federación Galega de Atletismo como responsable de esta web, asume el compromiso de
    procesar la información de los usuarios y clientes con plenas garantías y cumplir con los requisitos nacionales
    y europeos que regulan la recopilación y uso de los datos personales de los usuarios.</p>
<p>Esta web, por tanto, cumple rigurosamente con la Ley Orgánica 3/2018, de 5 de
    diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPDGDD), y con el Real
    Decreto 1720/2007, de 21 de diciembre, conocido como el Reglamento de desarrollo de la LOPD. Cumple también con
    el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección
    de las personas físicas (RGPD), así como con la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
    Información y Comercio Electrónico (LSSICE ó LSSI).</p>
<h3><strong>Condiciones generales de uso</strong></h3>
<p>Las presentes Condiciones Generales regulan el uso (incluyendo el mero acceso) de las
    páginas de web, integrantes del sitio web de Federación Galega de Atletismo incluidos los contenidos y servicios
    puestos a disposición en ellas. Toda persona que acceda a la web, https://www.carreirasgalegas.com (“usuario”)
    acepta someterse a las Condiciones Generales vigentes en cada momento del portal
    https://www.carreirasgalegas.com.</p>
<h3><strong>Datos personales que se recaban</strong></h3>
<p>Leer Política de Privacidad.</p>
<h3><strong>Compromisos y obligaciones de los usuarios</strong></h3>
<p>El usuario queda informado, y acepta, que el acceso a la presente web no supone, en
    modo alguno, el inicio de una relación comercial con Federación Galega de Atletismo. De esta forma, el usuario
    se compromete a utilizar el sitio web, sus servicios y contenidos sin contravenir la legislación vigente, la
    buena fe y el orden público.</p>
<p>Queda prohibido el uso de la web, con fines ilícitos o lesivos, o que, de cualquier
    forma, puedan causar perjuicio o impedir el normal funcionamiento del sitio web. Respecto de los contenidos de
    esta web, se prohíbe:</p>
<ul>
    <li>Su reproducción, distribución o modificación, total o
        parcial, a menos que se cuente con la autorización de Federación Galega de Atletismo como legítimo
        titular;</li>
    <li>Cualquier vulneración de los derechos del prestador como
        legítimo titular;</li>
    <li>Su utilización para fines comerciales o
        publicitarios.</li>
</ul>
<p>En la utilización de la web, https://www.carreirasgalegas.com el usuario se compromete
    a no llevar a cabo <strong>ninguna conducta</strong> que pudiera dañar la imagen,
    los intereses y los derechos de Federación Galega de Atletismo o de terceros o que pudiera dañar, inutilizar o
    sobrecargar el portal https://www.carreirasgalegas.com o que impidiera, de cualquier forma, la normal
    utilización de la web.</p>
<p>No obstante, el usuario debe ser consciente de que las medidas de seguridad de los
    sistemas informáticos en Internet no son enteramente fiables y que, por tanto https://www.carreirasgalegas.com
    no puede garantizar la inexistencia de malware u otros elementos que puedan producir alteraciones en los
    sistemas informáticos (software y hardware) del usuario o en sus documentos electrónicos y ficheros contenidos
    en los mismos aunque pongo todos los medios necesarios y las medidas de seguridad oportunas para evitar la
    presencia de estos elementos dañinos.</p>
<h3><strong>Medidas de seguridad</strong></h3>
<p>Los datos personales comunicados por el usuario a Federación Galega de Atletismo
    pueden ser almacenados en bases de datos automatizadas o no, cuya titularidad corresponde en exclusiva a
    Federación Galega de Atletismo, asumiendo ésta todas las medidas de índole técnica, organizativa y de seguridad
    que garantizan la confidencialidad, integridad y calidad de la información contenida en las mismas de acuerdo
    con lo establecido en la normativa vigente en protección de datos.La comunicación entre los usuarios y
    https://www.carreirasgalegas.com utiliza un canal seguro, y los datos transmitidos son cifrados gracias a
    protocolos a https, por tanto, garantizo las mejores condiciones de seguridad para que la confidencialidad de
    los usuarios esté garantizada.</p>
<h3><strong>Plataforma de resolución de conflictos</strong></h3>
<p>Se pone también a disposición de los usuarios la plataforma de resolución de litigios
    que facilita la Comisión Europea y que se encuentra disponible en el siguiente enlace: <a
        href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a>
</p>
<h3><strong>Derechos de propiedad intelectual e industrial</strong></h3>
<p>En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de
    Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación
    pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página
    web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de
    Federación Galega de Atletismo. El usuario se compromete a respetar los derechos de Propiedad Intelectual e
    Industrial titularidad de Federación Galega de Atletismo.</p>
<p>El usuario conoce y acepta que la totalidad del sitio web, conteniendo sin carácter
    exhaustivo el texto, software, contenidos (incluyendo estructura, selección, ordenación y presentación de los
    mismos) podcast, fotografías, material audiovisual y gráficos, está protegida por marcas, derechos de autor y
    otros derechos legítimos, de acuerdo con los tratados internacionales en los que España es parte y otros
    derechos de propiedad y leyes de España.</p>
<p>En el caso de que un usuario o un tercero consideren que se ha producido una violación
    de sus legítimos derechos de propiedad intelectual por la introducción de un determinado contenido en la web,
    deberá notificar dicha circunstancia a Federación Galega de Atletismo indicando:</p>
<ul>
    <li>Datos personales del interesado titular de los derechos
        presuntamente infringidos, o indicar la representación con la que actúa en caso de que la reclamación la
        presente un tercero distinto del interesado.</li>
    <li>Señalar los contenidos protegidos por los derechos de
        propiedad intelectual y su ubicación en la web, la acreditación de los derechos de propiedad intelectual
        señalados y declaración expresa en la que el interesado se responsabiliza de la veracidad de las
        informaciones facilitadas en la notificación.</li>
</ul>
<h3><strong>Enlaces externos</strong></h3>
<p>Las páginas de la web <a href="https://www.carreirasgalegas.com">https://www.carreirasgalegas.com</a>
    proporciona enlaces a otros sitios web propios y contenidos que son propiedad de terceros tales como:</p>
<ul>
    <li>Contenido de otros blogs</li>
    <li>Herramientas</li>
    <li>Recursos</li>
</ul>
<h3><strong>Exclusión de garantías y responsable</strong></h3>
<p><strong>Federación Galega de Atletismo no otorga ninguna garantía ni se hace responsable, en ningún caso, de los
        daños y
        perjuicios de cualquier naturaleza que pudieran traer causa de:</strong></p>
<ul>
    <li>La falta de disponibilidad, mantenimiento y efectivo
        funcionamiento de la web, o de sus servicios y contenidos;</li>
    <li>La existencia de malware, programas maliciosos o lesivos
        en los contenidos;</li>
    <li>El uso ilícito, negligente, fraudulento o contrario a
        este Aviso Legal;</li>
    <li>La falta de licitud, calidad, fiabilidad, utilidad y
        disponibilidad de los servicios prestados por terceros y puestos a disposición de los usuarios en el sitio
        web.</li>
    <li>El prestador no se hace responsable bajo ningún concepto
        de los daños que pudieran dimanar del uso ilegal o indebido de la presente página web.</li>
</ul>
<h3><strong>Ley aplicable y jurisdicción</strong></h3>
<p>Con carácter general las relaciones entre Federación Galega de Atletismo con los
    usuarios de sus servicios telemáticos, presentes en esta web se encuentran sometidas a la legislación y
    jurisdicción españolas y a los tribunales de A Coruña.</p>
<h3><strong>Contacto</strong></h3>
<p>En caso de que cualquier usuario tuviese alguna duda acerca de estas Condiciones
    legales o cualquier comentario sobre el portal https://www.carreirasgalegas.com, por favor diríjase a
    info@carreirasgalegas.com.</p>
<p>El presente aviso legal ha sido actualizado por última vez el 05-06-2023.</p>-->
                    <h3><strong>Datos do Responsable</strong></h3>
                    <ul>
                        <li><strong>Identidade do Responsable:</strong> Federación Galega de
                            Atletismo</li>
                        <li><strong>NIF/CIF:</strong> G15103500</li>
                        <li><strong>Dirección:</strong> Avda. Glasgow, 13 15008 A
                            Coruña</li>
                        <li><strong>Correo electrónico:</strong>
                            info@carreirasgalegas.com</li>
                    </ul>
                    <p>Neste espazo, o usuario, poderá atopar toda a información relativa aos
                        termos e condicións legais que definen as relacións entre os usuarios e eu como responsable
                        deste sitio web.
                        Como usuario, é importante que coñezas estes termos antes de continuar a túa navegación.</p>
                    <p>A Federación Galega de Atletismo, como responsable deste sitio web, asume o compromiso de
                        procesar a información dos usuarios e clientes con plenas garantías e cumprir cos requisitos
                        nacionais
                        e europeos que regulan a recollida e uso dos datos persoais dos usuarios.</p>
                    <p>Este sitio web, polo tanto, cumpre rigorosamente coa Lei Orgánica 3/2018, de 5 de
                        decembro, de Protección de Datos Persoais e garantía dos dereitos dixitais (LOPDGDD), e co Real
                        Decreto 1720/2007, de 21 de decembro, coñecido como o Regulamento de desenvolvemento da LOPD.
                        Cumpre tamén co
                        Regulamento (UE) 2016/679 do Parlamento Europeo e do Consello de 27 de abril de 2016 relativo á
                        protección
                        das persoas físicas (RGPD), así como coa Lei 34/2002, de 11 de xullo, de Servizos da Sociedade
                        da
                        Información e Comercio Electrónico (LSSICE ou LSSI).</p>
                    <h3><strong>Condicións xerais de uso</strong></h3>
                    <p>As presentes Condicións Xerais regulan o uso (incluíndo o mero acceso) das
                        páxinas do sitio web, integrantes do sitio web da Federación Galega de Atletismo incluídos os
                        contidos e servizos
                        postos á disposición nelas. Toda persoa que acceda ao sitio web,
                        https://www.carreirasgalegas.com (“usuario”)
                        acepta someterse ás Condicións Xerais vixentes en cada momento do portal
                        https://www.carreirasgalegas.com.</p>
                    <h3><strong>Datos persoais que se recollen</strong></h3>
                    <p>Ler Política de Privacidade.</p>
                    <h3><strong>Compromisos e obrigacións dos usuarios</strong></h3>
                    <p>O usuario queda informado, e acepta, que o acceso a este sitio web non supón, en
                        modo ningún, o inicio dunha relación comercial coa Federación Galega de Atletismo. Desta forma,
                        o usuario
                        comprométese a utilizar o sitio web, os seus servizos e contidos sen contravenir a lexislación
                        vixente, a
                        boa fe e a orde pública.</p>
                    <p>Queda prohibido o uso da web, con fins ilícitos ou lesivos, ou que, de calquera forma, poidan
                        causar prexuízo ou
                        impedir o normal funcionamento do sitio web. Respecto dos contidos desta web, prohíbese:</p>
                    <ul>
                        <li>A súa reprodución, distribución ou modificación, total ou parcial, agás que se conte coa
                            autorización da
                            Federación Galega de Atletismo como lexítimo titular;</li>
                        <li>Calquera vulneración dos dereitos do prestador como lexítimo titular;</li>
                        <li>A súa utilización para fins comerciais ou publicitarios.</li>
                    </ul>
                    <p>Na utilización da web, https://www.carreirasgalegas.com o usuario comprométese a non levar a cabo
                        <strong>ningunha
                            conduta</strong> que poida danar a imaxe, os intereses e os dereitos da Federación Galega de
                        Atletismo ou de
                        terceiros ou que poida danar, inutilizar ou sobrecargar o portal
                        https://www.carreirasgalegas.com ou que impedira,
                        de calquera forma, a normal utilización da web.
                    </p>
                    <p>Con todo, o usuario debe ser consciente de que as medidas de seguridade dos sistemas informáticos
                        na Internet non son
                        totalmente fiábeis e que, por tanto https://www.carreirasgalegas.com non pode garantir a
                        inexistencia de malware ou
                        outros elementos que poidan producir alteracións nos sistemas informáticos (software e hardware)
                        do usuario ou nos
                        seus documentos electrónicos e ficheiros contidos nos mesmos aínda que poño todos os medios
                        necesarios e as medidas
                        de seguridade oportunas para evitar a presenza destes elementos daninos.</p>
                    <h3><strong>Medidas de seguridade</strong></h3>
                    <p>Os datos persoais comunicados polo usuario á Federación Galega de Atletismo poden ser almacenados
                        en bases de datos
                        automatizadas ou non, cuxa titularidade corresponde en exclusiva á Federación Galega de
                        Atletismo, asumindo esta
                        todas as medidas de índole técnica, organizativa e de seguridade que garanten a
                        confidencialidade, integridade e
                        calidade da información contida nas mesmas de acordo co establecido na normativa vixente en
                        protección de datos.A
                        comunicación entre os usuarios e https://www.carreirasgalegas.com utiliza un canal seguro, e os
                        datos transmitidos
                        son cifrados grazas a protocolos a https, polo tanto, garanto as mellores condicións de
                        seguridade para que a
                        confidencialidade dos usuarios estea garantida.</p>
                    <h3><strong>Plataforma de resolución de conflitos</strong></h3>
                    <p>Tamén se pon á disposición dos usuarios a plataforma de resolución de litixios que facilita a
                        Comisión Europea e que
                        se atopa dispoñible no seguinte enlace: <a
                            href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a>
                    </p>
                    <h3><strong>Dereitos de propiedade intelectual e industrial</strong></h3>
                    <p>En virtude do disposto nos artigos 8 e 32.1, parágrafo segundo, da Lei de Propiedade Intelectual,
                        quedan expresamente
                        prohibidas a reprodución, a distribución e a comunicación pública, incluída a súa modalidade de
                        posta a disposición,
                        da totalidade ou parte dos contidos desta páxina web, con fins comerciais, en calquera soporte e
                        por calquera medio
                        técnico, sen a autorización da Federación Galega de Atletismo. O usuario comprométese a
                        respectar os dereitos de
                        Propiedade Intelectual e Industrial titularidade da Federación Galega de Atletismo.</p>
                    <p>O usuario coñece e acepta que a totalidade do sitio web, contendo sen carácter exhaustivo o
                        texto, software, contidos
                        (incluíndo estrutura, selección, ordenación e presentación dos mesmos) podcast, fotografías,
                        material audiovisual e
                        gráficos, está protexida por marcas, dereitos de autor e outros dereitos lexítimos, de acordo
                        coos tratados
                        internacionais nos que España é parte e outros dereitos de propiedade e leis de España.</p>
                    <p>No caso de que un usuario ou un terceiro consideren que se produciu unha violación dos seus
                        lexítimos dereitos de
                        propiedade intelectual pola introdución dun determinado contido na web, deberá notificar dita
                        circunstancia á
                        Federación Galega de Atletismo indicando:</p>
                    <ul>
                        <li>Datos persoais do interesado titular dos dereitos presuntamente infringidos, ou indicar a
                            representación coa que
                            actúa en caso de que a reclamación a presente un terceiro distinto do interesado.</li>
                        <li>Sinalar os contidos protexidos polos dereitos de propiedade intelectual e a súa ubicación na
                            web, a acreditación
                            dos dereitos de propiedade intelectual sinalados e declaración expresa na que o interesado
                            se responsabiliza da
                            veracidade das informacións facilitadas na notificación.</li>
                    </ul>
                    <h3><strong>Enlaces externos</strong></h3>
                    <p>As páxinas da web <a href="https://www.carreirasgalegas.com">https://www.carreirasgalegas.com</a>
                        proporciona
                        enlaces a outros sitios web propios e contidos que son propiedade de terceiros tales como:</p>
                    <ul>
                        <li>Contido de outros blogs</li>
                        <li>Ferramentas</li>
                        <li>Recursos</li>
                    </ul>
                    <h3><strong>Exclusión de garantías e responsable</strong></h3>
                    <p><strong>A Federación Galega de Atletismo non outorga ningunha garantía nin se fai responsable, en
                            ningún caso, dos
                            danos e prexuízos de calquera natureza que poidan traer causa de:</strong></p>
                    <ul>
                        <li>A falta de dispoñibilidade, mantemento e efectivo funcionamento da web, ou dos seus servizos
                            e contidos;</li>
                        <li>A existencia de malware, programas maliciosos ou lesivos nos contidos;</li>
                        <li>O uso ilícito, negligente, fraudulento ou contrario a este Aviso Legal;</li>
                        <li>A falta de licitude, calidade, fiabilidade, utilidade e dispoñibilidade dos servizos
                            prestados por terceiros e
                            postos á disposición dos usuarios no sitio web.</li>
                        <li>O prestador non se fai responsable baixo ningún concepto dos danos que poidan dimanar do uso
                            ilegal ou indebido
                            da presente páxina web.</li>
                    </ul>
                    <h3><strong>Lei aplicable e xurisdición</strong></h3>
                    <p>Con carácter xeneral as relacións entre a Federación Galega de Atletismo cos usuarios dos seus
                        servizos telemáticos,
                        presentes nesta web encontranse sometidas á lexislación e xurisdición españolas e aos tribunais
                        de A Coruña.</p>
                    <h3><strong>Contacto</strong></h3>
                    <p>En caso de que calquera usuario tivese algunha dúbida acerca destas Condicións legais ou calquera
                        comentario sobre o
                        portal https://www.carreirasgalegas.com, por favor diríxase a info@carreirasgalegas.com.</p>
                    <p>O presente aviso legal foi actualizado por última vez o 05-06-2023.</p>
                </div>
                <div class="mb-4 text-center"><img src="assets/img/cg-separator.png" alt=""></div>
            </div>
        </div>
    </div>
</section>