import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { map, pipe } from 'rxjs';
import { User } from '@angular/fire/auth';
import { LegalAdviceComponent } from './pages/legal-advice/legal-advice.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privecy-policy.component';
import { CookiesPolicyComponent } from './pages/cookies-policy/cookies-policy.component';
import { PaymentNokComponent } from './pages/payment/payment-nok.component';
import { PaymentOkComponent } from './pages/payment/payment-ok.component';
const redirectLoggedInToDashboard = () => pipe(
  map((user: User | null) => user && !user.isAnonymous ? [''] : true)
);
const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('src/app/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard },
  },
  {
    path: '', pathMatch: 'full',
    loadChildren: () => import('src/app/pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'news',
    loadChildren: () => import('src/app/pages/news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'events',
    loadChildren: () => import('src/app/pages/events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'results',
    loadChildren: () => import('src/app/pages/results/results.module').then(m => m.ResultsModule)
  },
  {
    path: 'circuits',
    loadChildren: () => import('src/app/pages/circuits/circuits.module').then(m => m.CircuitsModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('src/app/pages/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'runner',
    loadChildren: () => import('src/app/pages/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'legal-advice',
    component: LegalAdviceComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'cookies-policy',
    component: CookiesPolicyComponent
  },
  {
    path: 'payment-ok',
    component: PaymentOkComponent
  },
  {
    path: 'payment-nok',
    component: PaymentNokComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
