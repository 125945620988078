import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as CookieConsent from 'vanilla-cookieconsent';
import { DataLayerService } from './core/services/data-layer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  private previousUrl: string;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private dataLayerService: DataLayerService
  ) {
    this.router.events.subscribe((event) => {

      if (!(event instanceof NavigationEnd)) {
        return;
      } else {
        if (event.url !== '/auth/login') {  // '/login' debería ser reemplazado por la ruta de tu página de inicio de sesión
          this.previousUrl = event.url;
        }
        this.dataLayerService.logPageView(event.url);

      }
      window.scrollTo(0, 0);
    });
  }

  ngOnInit() {

    if (environment.useEmulators)
      this.afAuth.useEmulator('http://localhost:9099');
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  ngAfterViewInit(): void {
    CookieConsent.run({
      onConsent: function () {
        // consent was given
      },

      onChange: function () {
        // user changed his/her preferences
      },
      guiOptions: {
        consentModal: {
          layout: 'cloud inline',
          position: 'bottom',
          flipButtons: true,
          equalWeightButtons: true,
        }
      },
      categories: {
        necessary: {
          enabled: true,  // this category is enabled by default
          readOnly: true  // this category cannot be disabled
        },
        analytics: {
          enabled: false,
          readOnly: false,

          // Delete specific cookies when the user opts-out of this category
          autoClear: {
            cookies: [
              {
                name: /^_ga/,   // regex: match all cookies starting with '_ga'
              },
              {
                name: '_gid',   // string: exact cookie name
              }
            ]
          }
        }
      },

      language: {
        default: 'gl',

        translations: {
          en: {
            consentModal: {
              title: 'We use cookies',
              description: 'Cookie modal description',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage Individual preferences'
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  title: 'Somebody said ... cookies?',
                  description: 'I want one!'
                },
                {
                  title: 'Strictly Necessary cookies',
                  description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                  //this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: 'necessary'
                },
                {
                  title: 'Performance and Analytics',
                  description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                  linkedCategory: 'analytics'
                },
                {
                  title: 'More information',
                  description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
                }
              ]
            }
          },
          gl: {
            consentModal: {
              title: 'Usamos cookies',
              description: 'Usamos cookies para mellorar a navegación pola páxina web',
              acceptAllBtn: 'Aceptar todas',
              acceptNecessaryBtn: 'Rexeitar todas',
              showPreferencesBtn: 'Xestionar preferencias individuais'
            },
            preferencesModal: {
              title: 'Xestionar preferencias de cookies',
              acceptAllBtn: 'Aceptar todas',
              acceptNecessaryBtn: 'Rexeitar todas',
              savePreferencesBtn: 'Aceptar a selección actual',
              closeIconLabel: 'Pechar modal',
              sections: [
                {
                  title: 'Alguén dixo ... cookies?',
                  description: 'Quero unha!'
                },
                {
                  title: 'Cookies estritamente necesarias',
                  description: 'Estas cookies son esenciais para o correcto funcionamento do sitio web e non se poden desactivar.',
                  linkedCategory: 'necessary'
                },
                {
                  title: 'Rendemento e análise',
                  description: 'Estas cookies recollen información sobre como usas o noso sitio web. Todos os datos están anonimizados e non se poden usar para identificarte.',
                  linkedCategory: 'analytics'
                },
                {
                  title: 'Máis información',
                  description: 'Para calquera consulta en relación coa miña política sobre cookies e as túas opcións, por favor <a href="#contact-page">contacta con nós</a>'
                }
              ]
            }
          },
          es: {
            consentModal: {
              title: 'Usamos cookies',
              description: 'Usamos cookies para mejorar la navegación por la página web',
              acceptAllBtn: 'Aceptar todas',
              acceptNecessaryBtn: 'Rechazar todas',
              showPreferencesBtn: 'Gestionar preferencias individuales'
            },
            preferencesModal: {
              title: 'Gestionar preferencias de cookies',
              acceptAllBtn: 'Aceptar todas',
              acceptNecessaryBtn: 'Rechazar todas',
              savePreferencesBtn: 'Aceptar la selección actual',
              closeIconLabel: 'Cerrar modal',
              sections: [
                {
                  title: '¿Alguien dijo ... cookies?',
                  description: '¡Quiero una!'
                },
                {
                  title: 'Cookies estrictamente necesarias',
                  description: 'Estas cookies son esenciales para el correcto funcionamiento del sitio web y no se pueden desactivar.',
                  linkedCategory: 'necessary'
                },
                {
                  title: 'Rendimiento y análisis',
                  description: 'Estas cookies recopilan información sobre cómo usas nuestro sitio web. Todos los datos están anonimizados y no se pueden usar para identificarte.',
                  linkedCategory: 'analytics'
                },
                {
                  title: 'Más información',
                  description: 'Para cualquier consulta en relación a mi política sobre cookies y tus opciones, por favor <a href="#contact-page">contáctanos</a>'
                }
              ]
            }
          }
        }
      }
    });
  }

  ngOnDestroy() {

  }

  title = 'app';
}