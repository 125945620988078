import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './layout/nav-menu/nav-menu.component';
import { FooterComponent } from './layout/footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from 'src/environments/environment';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastNotifierService } from './core/toast-notifier.service';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { FullDateModule } from './shared/components/full-date/full-date.module';
import { InputMaskModule } from 'primeng/inputmask';

import { registerLocaleData } from '@angular/common';

import localeEs from '@angular/common/locales/es';
import localeGl from '@angular/common/locales/gl';
import localeExtraEs from '@angular/common/locales/extra/es';
import localeExtraGl from '@angular/common/locales/extra/gl';
import { TokenInterceptor } from './auth/auth.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UsersService } from './pages/users/users.service';
import { ConfigurationsService } from './core/services/configurations.service';
import { PaymentOkComponent } from './pages/payment/payment-ok.component';
import { PaymentNokComponent } from './pages/payment/payment-nok.component';

registerLocaleData(localeEs, 'es-ES', localeExtraEs);
registerLocaleData(localeGl, 'gl', localeExtraGl);

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    FooterComponent,
    PaymentOkComponent,
    PaymentNokComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastModule,
    ConfirmDialogModule,
    ButtonModule,
    RadioButtonModule,
    CheckboxModule,
    InputTextModule,
    DropdownModule,
    FullDateModule,
    InputMaskModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
  ],
  providers: [ConfirmationService, MessageService, ToastNotifierService, UsersService, ConfigurationsService,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost', 9099] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8081] : undefined },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useValue: "gl"
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
