export const environment = {
  production: true,
  domain: 'dev.carreirasgalegas.com',
  title: 'Carreiras Galegas - Develop',
  api: 'https://dev.api.web.carreirasgalegas.com',
  apiTp: 'https://dev.api.tp.carreirasgalegas.com',
  storage:
  {
    basicUrl: 'https://racesdev.blob.core.windows.net',
    circuits: "circuits",
    competitions: "competitions",
    regulations: "regulations",
    sponsors: "sponsors",
    races: "races",
    news: "news",
    resultsPdf: "results-pdf",
    bibsDocuments: "bibs-documents"
  },
  firebase: {
    apiKey: "AIzaSyAjoRvF4dqdLdmfgndkMsvHMhJOnXIkYtI",
    authDomain: "racesdev.firebaseapp.com",
    projectId: "racesdev",
    storageBucket: "racesdev.appspot.com",
    messagingSenderId: "475683178110",
    appId: "1:475683178110:web:2ec203c9f127fd026dfa2f"
  },
  firebaseCollections: {
    circuits: "circuits",
    competitions: "competitions",
    runners: "runners",
    enrollments: "enrollments",
    users: "users",
  },
  useEmulators: false,
  instagram: {
    token: 'IGQVJYNzlFd0cxN25qNEMyNGxjdFdkbEk4bE1FbE5ybC1PRmdIakhsTUl4U1R5QTNnWTNUeVplYWRYNnU3SFIyQjBuRVhjNk42dk9aaDIyVzFBQzJRNGtUNWliR05YTU1IU0VKVFVjLWl2NmVlcVRucgZDZD'
  },
  meilisearch: {
    host: 'https://search.carreirasgalegas.com',
    apiKey: '65534f2e820089a26d8e4dbb8a87630551dcf7389c3f2cd29e59d744a92ab410'
  },
  payment: {
    merchantCode: '349120709',
    currency: '978',
    terminal: '001',
    transactionType: '0',
    url: 'https://sis-t.redsys.es:25443/sis/realizarPago',
    merchantUrl: 'https://dev.api.web.carreirasgalegas.com/confirmation',
    merchantUrlBib0: 'https://dev.api.web.carreirasgalegas.com/confirmation-bib0',
    urlOk: 'https://dev.carreirasgalegas.com/payment-ok',
    urlKo: 'https://dev.carreirasgalegas.com/payment-nok',
    signatureVersion: 'HMAC_SHA256_V1',
    merchantName: 'Federación Galega de Atletismo'
  }
};
