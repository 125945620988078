import * as moment from "moment";
import { ConfigurationType } from "./enums/configuration-types";
import { normalizeTime } from "./functions";
import { clubNotBd } from "./models/club";
import { RunnerModel } from "./models/runner";
import { UserModel, UserPersonalDataModel } from "./models/user";


/********SAVE************/

export function saveRunnersStorage(user: UserModel, runners: RunnerModel[]) {
    runners = orderRunners(runners);
    let runnersStorage = [];
    runners.forEach(runner => {
        let runnerStorage = runnerToLocalStorage(runner, user);
        runnersStorage.push(runnerStorage);
    });
    localStorage.setItem("runners", JSON.stringify(runnersStorage));
}

export function updateRunnersStorage(runner: RunnerModel, userPersonalDataModel: UserPersonalDataModel) {
    let runners = JSON.parse(localStorage.getItem("runners"));
    let user: UserModel = {
        id: userPersonalDataModel.id,
        displayName: userPersonalDataModel.displayName,
        email: userPersonalDataModel.email,
        suscribedToNews: userPersonalDataModel.suscribedToNews,
        createdOn: undefined,
        isDppAccepted: true,
    }
    let runnerStorage = runnerToLocalStorage(runner, user);
    if (runners) {
        let index = runners.findIndex(r => r.personalDetails.id == runner.id);
        runners[index] = runnerStorage;
    }
    else { runners = [runnerStorage]; }

    localStorage.setItem("runners", JSON.stringify(runners));
}

function orderRunners(runners: RunnerModel[]): RunnerModel[] {
    runners = runners.sort((a, b) => { if (!a.parent) return -1; if (!b.parent) return 1; return 0; });
    return runners;
}


function runnerToLocalStorage(runner: RunnerModel, user: UserModel) {
    let runnerStorage = {
        personalDetails: getPersonalDetails(runner)
        , configurations: runner.configurations
        , user: { id: user.id, suscribedToNews: user.suscribedToNews, email: user.email }
        , createdOn: runner.createdOn
    };
    return runnerStorage;
}

export function getPersonalDetails(runner: RunnerModel) {
    let dateOfBirth = moment(runner.birthdate, "YYYY-MM-DD").startOf("day").toDate();
    let club = runner.club && runner.club.id ? runner.club : (runner.club ? clubNotBd : null);
    let personalDetails = {
        id: runner.id,
        documentType: runner.document.type,
        documentNumber: runner.document.number,
        name: runner.name,
        lastName: runner.lastName,
        dateOfBirth: {
            year: dateOfBirth.getFullYear().toString()
            , month: (dateOfBirth.getMonth() + 1).toString().padStart(2, '0')
            , day: dateOfBirth.getDate().toString().padStart(2, '0')
        },
        gender: runner.gender,
        nationality: runner.nationality,
        country: runner.country,
        state: runner.province,
        locality: runner.locality,
        phone: runner.phone,
        email: runner.email,
        code: runner.code,
        countryObject: { name: runner.country },
        stateObject: { name: runner.province },
        localityObject: { name: runner.locality },
        club: club,
        clubName: runner.club ? runner.club.name : "",
    }

    if (runner.parent) {
        personalDetails["parentDocumentType"] = runner.parent.document ? runner.parent.document.type : "";
        personalDetails["parentDocumentNumber"] = runner.parent.document ? runner.parent.document.number : "";
        personalDetails["parentName"] = runner.parent.name;
        personalDetails["parentLastName"] = runner.parent.lastName;
    }
    return personalDetails;
}


/********GET************/

export function getRunnerLocalStorage(index?: number) {
    if (!index) index = 0;
    let runner = null;
    let runners = JSON.parse(localStorage.getItem("runners"));
    if (runners && runners.length > 0 && index < runners.length) {
        runner = runners[index];
    }
    return runner;
}

export function getRunerUserLocalStorage() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (!user || user.isAnonymous) return null;

    let runner = getRunnerLocalStorage(0);
    if (!runner) {
        runner = {
            personalDetails: {
                name: user.displayName,
                email: user.email,
            },
            user: {
                id: user.uid, email: user.email
            }
        }
    }

    return runner;
}

export function getRaceDetailsLocalStorage(index?: number) {
    let runnerStorage = getRunnerLocalStorage(index);
    if (runnerStorage) {
        let raceDetails = runnerStorageToRaceDetails(runnerStorage)
        return raceDetails;
    }
    else return null;
}

export function runnerStorageToRaceDetails(runnerStorage) {
    if (runnerStorage) {
        let raceDetails = {
            club: runnerStorage.club,
            clubName: runnerStorage.clubName,
        };
        if (runnerStorage.configurations) {
            runnerStorage.configurations.forEach(configuration => {
                if (configuration.type == ConfigurationType.Time && configuration.value) {
                    let timeParts = configuration.value.replace(/\s/g, "").split(":");
                    const hours = normalizeTime(timeParts[0]);
                    const minutes = normalizeTime(timeParts[1]);
                    const seconds = normalizeTime(timeParts[2]);
                    raceDetails[configuration.id] = { hours: hours, minutes: minutes, seconds: seconds };
                }
                else if (configuration.type == ConfigurationType.Multiple
                    || configuration.type == ConfigurationType.Multitext) {
                    raceDetails[configuration.id] = configuration.values;
                }
                else {
                    raceDetails[configuration.id] = configuration.value;
                }
            });
        }
        return raceDetails;
    }
    else return null;
}


/*********REMOVE *********** */
export function cleanRunnerData() {
    localStorage.removeItem("runners");
}