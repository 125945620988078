import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()

export class TokenInterceptor implements HttpInterceptor {

    private excludedUrls: string[] = [
        'https://races.blob.core.windows.net/'
    ];

    constructor(
        private auth: AngularFireAuth
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.auth.idToken.pipe(
            mergeMap((token: any) => {
                if (token && !this.isExcluded(request.url)) {
                    request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
                }

                return next.handle(request);
            }));
    }

    private isExcluded(url: string): boolean {
        for (let excludedUrl of this.excludedUrls) {
            if (url.startsWith(excludedUrl)) {
                return true;
            }
        }
        return false;
    }
}