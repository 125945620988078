import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'payment-ok',
    templateUrl: './payment-ok.component.html'
})
export class PaymentOkComponent implements OnInit {

    constructor(private router: Router,) {

    }

    ngOnInit() {

        var redirect = localStorage.getItem('redirect');

        if (redirect !== null) {
            localStorage.removeItem('redirect');
            this.router.navigate([redirect]);
        }
    }
}