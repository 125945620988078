export enum ConfigurationType {
    Boolean = "boolean",
    Text = "text",
    Multitext = "multitext",
    Time = "time",
    Date = "date",
    Select = "select",
    Multiple = "multiple"
}

export class ConfigurationTypeType {
    label: string;
    value: ConfigurationType;
    static types: ConfigurationTypeType[] = [
        { value: ConfigurationType.Boolean, label: 'Si/No' },
        { value: ConfigurationType.Text, label: 'Texto' },
        { value: ConfigurationType.Multitext, label: 'Multitexto' },
        { value: ConfigurationType.Time, label: 'Hora' },
        { value: ConfigurationType.Date, label: 'Fecha' },
        { value: ConfigurationType.Select, label: 'Selector' },
        { value: ConfigurationType.Multiple, label: 'Múltiple' },
    ]
    static mapType(type: ConfigurationType): string {
        return this.types.filter(t => t.value == type).map<string>(f => f.label)[0];
    }
}

