<section class="legal">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="title mt-4 mt-lg-0"><span>Política de cookies</span></h2>
                <div class="mb-4 text-center"><img src="assets/img/cg-separator.png" alt=""></div>
                <div class="card-shadow">
                    <!--<h3>Pol&iacute;tica de cookies</h3>

<p>Una cookie es un peque&ntilde;o fichero de texto que se almacena en su navegador cuando visita casi cualquier
    p&aacute;gina web. Su utilidad es que la web sea capaz de recordar su visita cuando vuelva a navegar por esa
    p&aacute;gina. Las cookies suelen almacenar informaci&oacute;n de car&aacute;cter t&eacute;cnico, preferencias
    personales, personalizaci&oacute;n de contenidos, estad&iacute;sticas de uso, enlaces a redes sociales, acceso a
    cuentas de usuario, etc. El objetivo de la cookie es adaptar el contenido de la web a su perfil y necesidades, sin
    cookies los servicios ofrecidos por cualquier p&aacute;gina se ver&iacute;an mermados notablemente.</p>

<p><strong>&iquest;Qu&eacute; informaci&oacute;n almacena una cookie?</strong></p>

<p>Las cookies no suelen almacenar informaci&oacute;n sensible sobre usted, como tarjetas de cr&eacute;dito o datos
    bancarios, fotograf&iacute;as, su DNI o informaci&oacute;n personal, etc. Los datos que guardan son de
    car&aacute;cter t&eacute;cnico, preferencias personales, personalizaci&oacute;n de contenidos, etc.</p>

<p>
    El servidor web no le asocia a usted como persona si no a su navegador web. De hecho, si usted navega habitualmente
    con Internet Explorer y prueba a navegar por la misma web con Firefox o Chrome ver&aacute; que la web no se da
    cuenta que es usted la misma persona porque en realidad est&aacute; asociando al navegador, no a la persona.<br />
    &iquest;Qu&eacute; tipo de cookies existen?
</p>

<p>&nbsp;</p>

<ul>
    <li>Cookies t&eacute;cnicas: Son las m&aacute;s elementales y permiten, entre otras cosas, saber cu&aacute;ndo
        est&aacute; navegando un humano o una aplicaci&oacute;n automatizada, cu&aacute;ndo navega un usuario
        an&oacute;nimo y uno registrado, tareas b&aacute;sicas para el funcionamiento de cualquier web din&aacute;mica.
    </li>
    <li>Cookies de an&aacute;lisis: Recogen informaci&oacute;n sobre el tipo de navegaci&oacute;n que est&aacute;
        realizando, las secciones que m&aacute;s utiliza, productos consultados, franja horaria de uso, idioma, etc.
    </li>
    <li>Cookies publicitarias: Muestran publicidad en funci&oacute;n de su navegaci&oacute;n, su pa&iacute;s de
        procedencia, idioma, etc.</li>
</ul>

<p><strong>&iquest;Qu&eacute; son las cookies propias y las de terceros?</strong></p>

<p>Las cookies propias son las generadas por la p&aacute;gina que est&aacute; visitando y las de terceros son las
    generadas por servicios o proveedores externos como Facebook, Twitter, Google, etc.</p>

<p><strong>Cookies utilizadas en este sitio web</strong></p>

<p>Siguiendo las directrices de la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos procedemos a detallar el uso de
    cookies que hace esta web con el fin de informarle con la m&aacute;xima exactitud posible.</p>

<p>Este sitio web utiliza las siguientes cookies propias:</p>

<ul>
    <li>Cookies de sesi&oacute;n, para garantizar que los usuarios que escriban comentarios en el blog sean humanos y no
        aplicaciones automatizadas. De esta forma se combate el spam.</li>
</ul>

<p>Este sitio web utiliza las siguientes cookies de terceros:</p>

<ul>
    <li>Google Analytics: Almacena cookies para poder elaborar estad&iacute;sticas sobre el tr&aacute;fico y volumen de
        visitas de esta web. Al utilizar este sitio web est&aacute; consintiendo el tratamiento de informaci&oacute;n
        acerca de usted por Google. Por tanto, el ejercicio de cualquier derecho en este sentido deber&aacute; hacerlo
        comunicando directamente con Google.</li>
    <li>Redes sociales: Cada red social utiliza sus propias cookies para que usted pueda pinchar en botones del tipo Me
        gusta o Compartir.</li>
</ul>

<p><strong>&iquest;Se pueden eliminar las cookies?</strong></p>

<p>S&iacute;. No s&oacute;lo eliminar, tambi&eacute;n bloquear, de forma general o particular para un dominio
    espec&iacute;fico.</p>

<p>Para eliminar las cookies de un sitio web debe ir a la configuraci&oacute;n de su navegador y all&iacute;
    podr&aacute; buscar las asociadas al dominio en cuesti&oacute;n y proceder a su eliminaci&oacute;n.</p>

<p><strong>Configuraci&oacute;n de cookies para los navegadores m&aacute;s populares</strong></p>

<p>A continuaci&oacute;n le indicamos c&oacute;mo acceder a una cookie determinada del navegador Chrome. Nota: estos
    pasos pueden variar en funci&oacute;n de la versi&oacute;n del navegador:</p>

<ol>
    <li>Vaya a Configuraci&oacute;n o Preferencias mediante el men&uacute; Archivo o bien pinchando el icono de
        personalizaci&oacute;n que aparece arriba a la derecha.</li>
    <li>Ver&aacute; diferentes secciones, pinche la opci&oacute;n Mostrar opciones avanzadas.</li>
    <li>Vaya a Privacidad, Configuraci&oacute;n de contenido.</li>
    <li>Seleccione Todas las cookies y los datos de sitios.</li>
    <li>Aparecer&aacute; un listado con todas las cookies ordenadas por dominio. Para que le sea m&aacute;s f&aacute;cil
        encontrar las cookies de un determinado dominio introduzca parcial o totalmente la direcci&oacute;n en el campo
        Buscar cookies.</li>
    <li>Tras realizar este filtro aparecer&aacute;n en pantalla una o varias l&iacute;neas con las cookies de la web
        solicitada. Ahora s&oacute;lo tiene que seleccionarla y pulsar la X para proceder a su eliminaci&oacute;n.</li>
</ol>

<p>Para acceder a la configuraci&oacute;n de cookies del navegador Internet Explorer siga estos pasos (pueden variar en
    funci&oacute;n de la versi&oacute;n del navegador):</p>

<ol>
    <li>Vaya a Herramientas, Opciones de Internet</li>
    <li>Haga click en Privacidad.</li>
    <li>Mueva el deslizador hasta ajustar el nivel de privacidad que desee.</li>
</ol>

<p>Para acceder a la configuraci&oacute;n de cookies del navegador Firefox siga estos pasos (pueden variar en
    funci&oacute;n de la versi&oacute;n del navegador):</p>

<ol>
    <li>Vaya a Opciones o Preferencias seg&uacute;n su sistema operativo.</li>
    <li>Haga click en Privacidad.</li>
    <li>En Historial elija Usar una configuraci&oacute;n personalizada para el historial.</li>
    <li>Ahora ver&aacute; la opci&oacute;n Aceptar cookies, puede activarla o desactivarla seg&uacute;n sus
        preferencias.</li>
</ol>

<p>Para acceder a la configuraci&oacute;n de cookies del navegador Safari para OSX siga estos pasos (pueden variar en
    funci&oacute;n de la versi&oacute;n del navegador):</p>

<ol>
    <li>Vaya a Preferencias, luego Privacidad.</li>
    <li>En este lugar ver&aacute; la opci&oacute;n Bloquear cookies para que ajuste el tipo de bloqueo que desea
        realizar.</li>
</ol>

<p>Para acceder a la configuraci&oacute;n de cookies del navegador Safari para iOS siga estos pasos (pueden variar en
    funci&oacute;n de la versi&oacute;n del navegador):</p>

<ol>
    <li>Vaya a Ajustes, luego Safari.</li>
    <li>Vaya a Privacidad y Seguridad, ver&aacute; la opci&oacute;n Bloquear cookies para que ajuste el tipo de bloqueo
        que desea realizar.</li>
</ol>

<p>Para acceder a la configuraci&oacute;n de cookies del navegador para dispositivos Android siga estos pasos (pueden
    variar en funci&oacute;n de la versi&oacute;n del navegador):</p>

<ol>
    <li>Ejecute el navegador y pulse la tecla Men&uacute;, luego Ajustes.</li>
    <li>Vaya a Seguridad y Privacidad, ver&aacute; la opci&oacute;n Aceptar cookies para que active o desactive la
        casilla.</li>
</ol>

<p>Para acceder a la configuraci&oacute;n de cookies del navegador para dispositivos Windows Phone siga estos pasos
    (pueden variar en funci&oacute;n de la versi&oacute;n del navegador):</p>

<ol>
    <li>Abra Internet Explorer, luego M&aacute;s, luego Configuraci&oacute;n</li>
    <li>Ahora puede activar o desactivar la casilla Permitir cookies.</li>
</ol>

<p><strong>Desactivaci&oacute;n o eliminaci&oacute;n de cookies</strong></p>

<p>En cualquier momento podr&aacute; ejercer su derecho de desactivaci&oacute;n o eliminaci&oacute;n de cookies de este
    sitio web. Estas acciones se realizan de forma diferente en funci&oacute;n del navegador que est&eacute; usando.</p>

<p>Notas adicionales</p>

<ul>
    <li>Ni esta web ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad de las
        pol&iacute;ticas de privacidad que puedan tener los terceros mencionados en esta pol&iacute;tica de cookies.
    </li>
    <li>Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar debe efectuar su
        derecho a eliminaci&oacute;n o desactivaci&oacute;n de las mismas. Ni esta web ni sus representantes legales
        pueden garantizar la correcta o incorrecta manipulaci&oacute;n de las cookies por parte de los mencionados
        navegadores.</li>
    <li>En algunos casos es necesario instalar cookies para que el navegador no olvide su decisi&oacute;n de no
        aceptaci&oacute;n de las mismas.</li>
    <li>En el caso de las cookies de Google Analytics, esta empresa almacena las cookies en servidores ubicados en
        Estados Unidos y se compromete a no compartirla con terceros, excepto en los casos en los que sea necesario para
        el funcionamiento del sistema o cuando la ley obligue a tal efecto. Seg&uacute;n Google no guarda su
        direcci&oacute;n IP. Google Inc. es una compa&ntilde;&iacute;a adherida al Acuerdo de Puerto Seguro que
        garantiza que todos los datos transferidos ser&aacute;n tratados con un nivel de protecci&oacute;n acorde a la
        normativa europea. Puede consultar informaci&oacute;n detallada a este respecto en <a
            href="http://safeharbor.export.gov/companyinfo.aspx?id=16626" target="_blank">este enlace</a>. Si desea
        informaci&oacute;n sobre el uso que Google da a las cookies <a
            href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es&csw=1"
            target="_blank">le adjuntamos este otro enlace</a>.</li>
    <li>Para cualquier duda o consulta acerca de esta pol&iacute;tica de cookies no dude en comunicarse con nosotros a
        trav&eacute;s de la secci&oacute;n de contacto.</li>
</ul>-->
                    <p>Unha cookie é un pequeno ficheiro de texto que se almacena no seu navegador cando visita case
                        calquera
                        páxina web. A súa utilidade é que a web sexa quen de recordar a súa visita cando volva a navegar
                        por esa
                        páxina. As cookies adoitan almacenar información de carácter técnico, preferencias
                        persoais, personalización de contidos, estatísticas de uso, ligazóns a redes sociais, acceso a
                        contas de usuario, etc. O obxectivo da cookie é adaptar o contido da web ao seu perfil e
                        necesidades, sen
                        cookies os servizos ofrecidos por calquera páxina veríanse mermados notabelmente.</p>
                    <h3>&iquest;Qué información almacena unha cookie?</h3>
                    <p>As cookies non adoitan almacenar información sensible sobre vostede, como tarxetas de crédito ou
                        datos
                        bancarios, fotografías, o seu DNI ou información persoal, etc. Os datos que gardan son de
                        carácter técnico, preferencias persoais, personalización de contidos, etc.</p>
                    <p>
                        O servidor web non lle asocia a vostede como persoa se non ao seu navegador web. De feito, se
                        vostede navega
                        habitualmente
                        con Internet Explorer e proba a navegar pola mesma web con Firefox ou Chrome verá que a web non
                        se da
                        conta que é vostede a mesma persoa porque en realidade está asociando ao navegador, non á
                        persoa.<br />
                        &iquest;Qué tipo de cookies existen?
                    </p>
                    <ul>
                        <li>Cookies técnicas: Son as máis elementais e permiten, entre outras cousas, saber cando
                            está a navegar un humano ou unha aplicación automatizada, cando navega un usuario
                            anónimo e un rexistrado, tarefas básicas para o funcionamento de calquera web dinámica.
                        </li>
                        <li>Cookies de análise: Recollen información sobre o tipo de navegación que está a
                            realizar, as seccións que máis utiliza, produtos consultados, franxa horaria de uso, idioma,
                            etc.
                        </li>
                        <li>Cookies publicitarias: Amosan publicidade en función da súa navegación, o seu país de
                            procedencia, idioma, etc.</li>
                    </ul>
                    <h3>&iquest;Qué son as cookies propias e as de terceiros?</h3>
                    <p>As cookies propias son as xeradas pola páxina que está a visitar e as de terceiros son as
                        xeradas por servizos ou provedores externos como Facebook, Twitter, Google, etc.</p>
                    <h3>Cookies utilizadas neste sitio web</h3>
                    <p>Seguindo as directrices da Axencia Española de Protección de Datos procedemos a detallar o uso de
                        cookies que fai esta web co fin de informarlle coa máxima exactitude posible.</p>
                    <p>Este sitio web utiliza as seguintes cookies propias:</p>
                    <ul>
                        <li>Cookies de sesión, para garantir que os usuarios que escriban comentarios no blog sexan
                            humanos e non
                            aplicacións automatizadas. Deste xeito combate o spam.</li>
                    </ul>
                    <p>Este sitio web utiliza as seguintes cookies de terceiros:</p>
                    <ul>
                        <li>Google Analytics: Almacena cookies para poder elaborar estatísticas sobre o tráfico e volume
                            de
                            visitas desta web. Ao utilizar este sitio web está a consentir o tratamento de información
                            acerca de vostede por Google. Por tanto, o exercicio de calquera dereito neste sentido
                            deberá facelo
                            comunicando directamente con Google.</li>
                        <li>Redes sociais: Cada rede social utiliza as súas propias cookies para que vostede poida
                            pinchar en botóns do tipo
                            Gústame
                            ou Compartir.</li>
                    </ul>
                    <h3>&iquest;Pódense eliminar as cookies?</h3>
                    <p>Si. Non só eliminar, tamén bloquear, de maneira xeral ou particular para un dominio
                        específico.
                    </p>
                    <p>Para eliminar as cookies dun sitio web debe ir á configuración do seu navegador e alí poderá
                        buscar as asociadas ao
                        dominio en cuestión e proceder á súa eliminación.</p>
                    <h3>Configuración de cookies para os navegadores máis populares</h3>
                    <p>A continuación indicámoslle cómo acceder a unha cookie determinada do navegador Chrome.
                        Nota:
                        estes pasos poden
                        variar en función da versión do navegador:</p>
                    <ol>
                        <li>Vaia a Configuración ou Preferencias mediante o menú Arquivo ou ben premendo o icono
                            de
                            personalización que
                            aparece enriba á dereita.</li>
                        <li>Verá diferentes seccións, prema a opción Mostrar opcións avanzadas.</li>
                        <li>Vaia a Privacidade, Configuración de contido.</li>
                        <li>Seleccione Todas as cookies e os datos de sitios.</li>
                        <li>Aparecerá un listado con todas as cookies ordenadas por dominio. Para que lle sexa
                            máis
                            fácil atopar as cookies
                            dun determinado dominio introduza parcial ou totalmente a dirección no campo Buscar
                            cookies.
                        </li>
                        <li>Tras realizar este filtro aparecerán en pantalla unha ou varias liñas coas cookies
                            da web
                            solicitada. Agora só
                            ten que seleccionarla e premir a X para proceder á súa eliminación.</li>
                    </ol>
                    <p>Para acceder á configuración de cookies do navegador Internet Explorer siga estes pasos
                        (poden
                        variar en función da
                        versión do navegador):</p>
                    <ol>
                        <li>Vaia a Ferramentas, Opcións de Internet</li>
                        <li>Prema en Privacidade.</li>
                        <li>Desprace o deslizador ata axustar o nivel de privacidade que desexa.</li>
                    </ol>
                    <p>Para acceder á configuración de cookies do navegador Firefox siga estes pasos (poden
                        variar en
                        función da versión do
                        navegador):</p>
                    <ol>
                        <li>Vaia a Opcións ou Preferencias segundo o seu sistema operativo.</li>
                        <li>Prema en Privacidade.</li>
                        <li>En Historial escolle Usar unha configuración personalizada para o historial.</li>
                        <li>Agora verá a opción Aceptar cookies, pode activala ou desactivala segundo as súas
                            preferencias.</li>
                    </ol>
                    <p>Para acceder á configuración de cookies do navegador Safari para OSX siga estes pasos
                        (poden
                        variar en función da
                        versión do navegador):</p>
                    <ol>
                        <li>Vaia a Preferencias, logo Privacidade.</li>
                        <li>Neste lugar verá a opción Bloquear cookies para que axuste o tipo de bloqueo que
                            desexa
                            realizar.</li>
                    </ol>
                    <p>Para acceder á configuración de cookies do navegador Safari para iOS siga estes pasos
                        (poden
                        variar en función da
                        versión do navegador):</p>
                    <ol>
                        <li>Vaia a Axustes, logo Safari.</li>
                        <li>Vaia a Privacidade e Seguridade, verá a opción Bloquear cookies para que axuste o
                            tipo de
                            bloqueo que desexa
                            realizar.</li>
                    </ol>
                    <p>Para acceder á configuración de cookies do navegador para dispositivos Android siga estes
                        pasos
                        (poden variar en
                        función da versión do navegador):</p>
                    <ol>
                        <li>Executar o navegador e prema a tecla Menú, logo Axustes.</li>
                        <li>Vaia a Seguridade e Privacidade, verá a opción Aceptar cookies para que active ou
                            desactive
                            a casilla.</li>
                    </ol>
                    <p>Para acceder á configuración de cookies do navegador para dispositivos Windows Phone siga
                        estes
                        pasos (poden variar
                        en función da versión do navegador):</p>
                    <ol>
                        <li>Abra Internet Explorer, logo Máis, logo Configuración</li>
                        <li>Agora pode activar ou desactivar a casilla Permitir cookies.</li>
                    </ol>
                    <h3>Desactivación ou eliminación de cookies</h3>
                    <p>En calquera momento poderá exercer o seu dereito de desactivación ou eliminación de
                        cookies deste
                        sitio web. Estas
                        accións realízanse de maneira diferente en función do navegador que estea usando.
                    </p>
                    <h3>Notas adicionais</h3>
                    <ul>
                        <li>Nin este web nin os seus representantes legais fanse responsables nin do
                            contido nin da
                            veracidade das políticas
                            de privacidade que poidan ter os terceiros mencionados nesta política de
                            cookies.</li>
                        <li>Os navegadores web son as ferramentas encargadas de almacenar as cookies e
                            desde este lugar
                            debe efectuar o seu
                            dereito a eliminación ou desactivación das mesmas. Nin este web nin os seus
                            representantes
                            legais poden garantir
                            a correcta ou incorrecta manipulación das cookies por parte dos mencionados
                            navegadores.
                        </li>
                        <li>En alguns casos é necesario instalar cookies para que o navegador non
                            esqueza a súa decisión
                            de non aceptación
                            das mesmas.</li>
                        <li>No caso das cookies de Google Analytics, esta empresa almacena as cookies en
                            servidores
                            ubicados nos Estados
                            Unidos e comprométese a non compartila con terceiros, excepto nos casos nos
                            que sexa
                            necesario para o
                            funcionamento do sistema ou cando a lei obrigue a tal efecto. Segundo Google
                            non garda a súa
                            dirección IP.
                            Google Inc. é unha compañía adherida ao Acordo de Porto Seguro que garante
                            que todos os
                            datos transferidos serán
                            tratados cun nivel de protección acorde á normativa europea. Pode consultar
                            información
                            detallada a este
                            respecto en <a href="http://safeharbor.export.gov/companyinfo.aspx?id=16626"
                                target="_blank">este enlace</a>. Se
                            desexa información sobre o uso que Google dá as cookies <a
                                href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es&csw=1"
                                target="_blank">anexámoslle este outro enlace</a>.</li>
                        <li>Para calquera dúbida ou consulta acerca desta política de cookies non dubide
                            en comunicarse
                            connosco a través da
                            sección de contacto.</li>
                    </ul>
                    <p>A presente política de cookies foi actualizada por última vez o 05-06-2023.</p>
                </div>
                <div class="mb-4 text-center"><img src="assets/img/cg-separator.png" alt=""></div>
            </div>
        </div>
    </div>
</section>