import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { LazyLoadEvent } from "primeng/api";
import { Observable } from "rxjs";
import { UserRunnerModel } from "src/app/core/models/user";
import { HttpParamsBuilder } from "src/app/shared/http-params.builder";
import { PaginatedResult } from "src/app/shared/paginated-result";
import { environment } from "src/environments/environment";

@Injectable()
export class UsersService {

    constructor(private http: HttpClient,
        private afs: AngularFirestore) { }

    private buildUrl(id?: string): string {
        if (id) return `${environment.api}/users/${id}`
        return `${environment.api}/users`
    }
    private buildUrlRunners(id?: string): string {
        if (id)
            return `${environment.api}/runners/${id}`
        return `${environment.api}/runners`
    }

    getUser(uid: string): Observable<any> {
        return this.afs.collectionGroup<any>(environment.firebaseCollections.users,
            ref => ref.where('id', '==', uid)).valueChanges();
    }

    getRunnersByUserId(id: string): Observable<any> {
        return this.afs.collectionGroup<any>(environment.firebaseCollections.runners,
            ref => ref.where('userId', '==', id)).valueChanges();
    }
    getRunnerByDocumentNumber(documentNumber: string): Observable<any> {
        return this.afs.collectionGroup<any>(environment.firebaseCollections.runners,
            ref => ref.where('document.number', '==', documentNumber)).valueChanges();
    }
    updateRunner(runnerId: string, runner: UserRunnerModel): Observable<any> {
        return this.http.put<any>(`${this.buildUrlRunners(runnerId)}`, runner)
    }
    createRunner(runner: UserRunnerModel): Observable<any> {
        return this.http.post<any>(`${this.buildUrlRunners()}`, runner)
    }


    getAllEnrollmentsByRunner(runnerId: string, event: LazyLoadEvent): Observable<any> {
        let params = HttpParamsBuilder.fromLazyEvent(event);
        params = params.set("runnerId", runnerId);
        return this.http.get<PaginatedResult<any>>(
            `${this.buildUrlRunners(runnerId)}/enrollments`, { params: params }
        )
    }
    getAllResultsByRunner(runnerId: string, code: string, event: LazyLoadEvent): Observable<any> {
        let params = HttpParamsBuilder.fromLazyEvent(event);
        params = params.set("code", code);
        return this.http.get<PaginatedResult<any>>(
            `${this.buildUrlRunners(runnerId)}/results`, { params: params }
        )
    }
}