<section class="legal">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="title mt-4 mt-lg-0"><span>Erro na inscrición</span></h2>
                <div class="mb-4 text-center"><img src="assets/img/cg-separator.png" alt=""></div>
                <div class="card-shadow">
                    <p>Ocorreu un erro durante a inscrición, por favor inténtao de novo, e se o problema persiste, ponte
                        en contacto con nós
                        en info@carreirasgalegas.com</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="mb-4 text-center"></div>