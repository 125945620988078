
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { InputTextModule } from "primeng/inputtext";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { DropdownModule } from "primeng/dropdown";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FullDateComponent } from "./full-date.component";
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from "primeng/inputmask";

@NgModule({
    declarations: [
        FullDateComponent
    ],
    exports: [FullDateComponent],
    imports: [

        CommonModule,
        DropdownModule,
        FormsModule,
        InputTextModule,
        ReactiveFormsModule,
        ToolbarModule,
        TooltipModule,
        CalendarModule,
        InputMaskModule
    ],
    providers: [
    ]
})

export class FullDateModule { }
