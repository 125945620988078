import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { getDisplayName, getInitials } from 'src/app/core/functions';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  @ViewChild('header', { static: true }) header: ElementRef;

  private lastScrollTop: number = 0;

  isNavOpen = false;
  isExpanded = false;
  activeLink: string;
  isNavActive = false;
  activeLinks: string;
  user: any;
  avatar: string;
  displayName: string

  constructor(private router: Router,
    public afAuth: AngularFireAuth,
    public authService: AuthService) {
    this.activeLink = this.router.url;
    this.activeLink = window.location.pathname;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeLink = event.url;
      }
    });

  }

  toggleNav() {
    this.isNavOpen = !this.isNavOpen;
    this.isNavActive = !this.isNavActive;
    if (this.isNavActive) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    if (this.isNavOpen === false) {
      this.router.navigate([''], { replaceUrl: true });
    }
  }

  closeNav() {
    this.isNavOpen = false;
    this.isNavActive = false;
    document.body.classList.remove('no-scroll');
  }

  ngOnInit() {
    this.afAuth.authState.subscribe((user) => {
      this.user = user;
      this.avatar = this.user ? getInitials(this.user) : "";
      this.displayName = this.user ? getDisplayName(this.user) : "";
    });
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logout() {
    this.authService.SignOut();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    const st = window.pageYOffset;

    const headerElem = this.header.nativeElement;

    if (0 < st) {
      headerElem.classList.add('header-top');
    } else {
      headerElem.classList.remove('header-top');
    }

    if (st < this.lastScrollTop || window.innerWidth < 768) {
      if (0 === st) {
        headerElem.classList.remove('header-scroll');
      } else {
        headerElem.classList.add('header-scroll');
      }
    } else {
      headerElem.classList.remove('header-scroll');
    }

    this.lastScrollTop = st;
  }
}
