<section class="legal">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="title mt-4 mt-lg-0"><span>Inscrición</span></h2>
                <div class="mb-4 text-center"><img src="assets/img/cg-separator.png" alt=""></div>
                <div class="card-shadow">
                    Pago realizado correctamente
                </div>
            </div>
        </div>
    </div>
</section>
<div class="mb-4 text-center"></div>