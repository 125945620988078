import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cookies-policy',
    templateUrl: './cookies-policy.component.html'
})
export class CookiesPolicyComponent implements OnInit {

    constructor() { }
    ngOnInit() {
    }


}
