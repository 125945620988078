import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-legal-advice',
    templateUrl: './legal-advice.component.html'
})
export class LegalAdviceComponent implements OnInit {

    constructor() { }
    ngOnInit() {
        localStorage.removeItem('redirect');
    }


}