<section class="legal">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="title mt-4 mt-lg-0"><span>Política de privacidade</span></h2>
                <div class="mb-4 text-center"><img src="assets/img/cg-separator.png" alt=""></div>
                <div class="card-shadow">
                    <!--
<p>La presente declaración de privacidad explica qué datos personales se recopilan de los
    usuarios y cómo se utilizan. </p>
<p>En esta web se respetan y cuidan los datos personales de los usuarios. Como usuario
    debes saber que tus derechos están garantizados.</p>
<p>Nos hemos esforzado en crear un espacio seguro y confiable y por eso queremos
    compartir nuestros principios respecto a la privacidad de los usuarios:</p>
<ul>
    <li>Nunca solicitamos información personal a menos que
        realmente sea necesaria para prestarte los servicios que requiera el usuario.</li>
    <li>Nunca compartimos información personal de los usuarios
        con nadie, excepto para cumplir con la ley o en caso que cuente con tu autorización expresa.</li>
    <li>Nunca utilizamos los datos personales de los usuarios
        con una finalidad diferente a la expresada en esta política de privacidad.</li>
</ul>
<p>Es preciso advertir que esta Política de Privacidad podría variar en función de
    exigencias legislativas o de autorregulación, por lo que se aconseja a los usuarios que la visiten
    periódicamente. Será aplicable en caso de que los usuarios decidan rellenar algún formulario de cualquiera de
    sus formularios de contacto donde se recaben datos de carácter personal.</p>
<p>Federación Galega de Atletismo ha adecuado esta web a las exigencias de la Ley
    Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales
    (LOPDGDD), y al Real Decreto 1720/2007, de 21 de diciembre, conocido como el Reglamento de desarrollo de la
    LOPD. Cumple también con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016
    relativo a la protección de las personas físicas (RGPD), así como con la Ley 34/2002, de 11 de julio, de
    Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE o LSSI).</p>
<p>Responsable del tratamiento de datos personales</p>
<ul>
    <li>Identidad del Responsable: Federación Galega de
        Atletismo</li>
    <li>NIF/CIF: G15103500</li>
    <li>Dirección: Avda. Glasgow, 13. 15008 A Coruña</li>
    <li>Correo electrónico: info@carreirasgalegas.com
    </li>
    <li>Actividad: Gestión y organización de actividades
        relacionadas con el atletismo.</li>
</ul>
<p>A efectos de lo previsto en el Reglamento General de Protección de Datos antes citado,
    los datos personales que se envíen a través de los formularios de la web, recibirán el tratamiento de datos de
    “Usuarios de la web y suscriptores”.</p>
<p>Para el tratamiento de datos de los usuarios, se implementan todas las medidas
    técnicas y organizativas de seguridad establecidas en la legislación vigente.</p>
<p>En el tratamiento de los datos personales, se aplicarán los siguientes principios que
    se ajustan a las exigencias del nuevo reglamento europeo de protección de datos:</p>
<ul>
    <li>Principio de licitud, lealtad y transparencia: Siempre
        se requerirá el consentimiento del usuario para el tratamiento de sus datos personales para uno o varios
        fines específicos que se informarán previamente con absoluta transparencia.</li>
    <li>Principio de minimización de datos: Solo se solicitarán
        datos estrictamente necesarios en relación con los fines para los que se requieren. Los mínimos
        posibles.</li>
    <li>Principio de limitación del plazo de conservación: los
        datos serán mantenidos durante no más tiempo del necesario para los fines del tratamiento, en función a la
        finalidad, se informará del plazo de conservación correspondiente, en el caso de suscripciones,
        periódicamente se revisarán las listas y se eliminarán aquellos registros inactivos durante un tiempo
        considerable.</li>
    <li>Principio de integridad y confidencialidad: Los datos
        serán tratados de tal manera que se garantice una seguridad adecuada de los datos personales y se garantice
        confidencialidad. Se toman todas las precauciones necesarias para evitar el acceso no autorizado o uso
        indebido de los datos de los usuarios por parte de terceros.</li>
</ul>
<p>¿Cómo he obtenido los datos?</p>
<p>Los datos personales que se tratan en Federación Galega de Atletismo proceden
    de:</p>
<ul>
    <li>Formulario de contacto</li>
    <li>Comunicaciones directas con los usuarios</li>
</ul>
<p><strong>Derechos sobre los datos:</strong></p>
<p>Cualquier persona tiene derecho a obtener confirmación sobre si en Federación Galega
    de Atletismo se están tratando datos personales.</p>
<p>Las personas interesadas tienen derecho a:</p>
<ul>
    <li>Solicitar el acceso a los datos personales relativos al
        interesado</li>
    <li>Solicitar su rectificación o supresión</li>
    <li>Solicitar la limitación de su tratamiento</li>
    <li>Oponerse al tratamiento</li>
    <li>Solicitar la portabilidad de los datos</li>
</ul>
<p>Los interesados podrán acceder a sus datos personales, así como a solicitar la
    rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los
    datos ya no sean necesarios para los fines que fueron recogidos. En determinadas circunstancias, los interesados
    podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente se conservarán para el
    ejercicio o la defensa de reclamaciones.</p>
<p>En determinadas circunstancias y por motivos relacionados con su situación particular,
    los interesados podrán oponerse al tratamiento de sus datos. Federación Galega de Atletismo dejará de tratar los
    datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones. Cómo
    interesado, el usuario tiene derecho a recibir los datos personales que le incumban, que hayan sido facilitadoo
    y en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro responsable del
    tratamiento cuando:</p>
<ul>
    <li>El tratamiento esté basado en el consentimiento
    </li>
    <li>Los datos hayan sido facilitados por la persona
        interesada.</li>
    <li>El tratamiento se efectúe por medios
        automatizados.</li>
</ul>
<p>Al ejercer el derecho a la portabilidad de los datos, el usuario tendrá derecho a que
    los datos personales se transmitan directamente de responsable a responsable cuando sea técnicamente
    posible.</p>
<p>Los interesados también tendrán derecho a la tutela judicial efectiva y a presentar
    una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si
    consideran que el tratamiento de datos personales que le conciernen infringe el Reglamento.</p>
<p><strong>Finalidad de los datos personales:</strong></p>
<p>Cuando un usuario se conecta con esta web por ejemplo para mandar un correo al
    titular, suscribirse o realizar alguna contratación, está facilitando información de carácter personal de la que
    es responsable Federación Galega de Atletismo. Esa información puede incluir datos de carácter personal como
    pueden ser: dirección IP, nombre, dirección física, dirección de correo electrónico, número de teléfono, y otra
    información. Al facilitar esta información, el usuario da su consentimiento para que su información sea
    recopilada, utilizada, gestionada y almacenada por Federación Galega de Atletismo, sólo como se describe en el
    Aviso Legal y en la presente Política de Privacidad.</p>
<p>Existen otras finalidades por la que se tratan los datos personales:</p>
<ul>
    <li>Para garantizar el cumplimiento de las condiciones de
        uso y la ley aplicable. Esto puede incluir el desarrollo de herramientas y algoritmos que ayudan a esta web
        a garantizar la confidencialidad de los datos personales que recoge.</li>
    <li>Para apoyar y mejorar los servicios que ofrece esta
        web.</li>
    <li>También se recogen otros datos no identificativos que se
        obtienen mediante algunas cookies que se descargan en el ordenador del usuario cuando navega en esta web que
        detallo en la política de cookies.</li>
    <li>Para gestionar las redes sociales. Federación Galega de
        Atletismo puede tener presencia en redes sociales. El tratamiento de los datos que se lleve a cabo de las
        personas que se hagan seguidoras en las redes sociales de las páginas oficiales de
        https://www.carreirasgalegas.com, se regirá por este apartado. Así como por aquellas condiciones de uso,
        políticas de privacidad y normativas de acceso que pertenezcan a la red social que proceda en cada caso y
        aceptadas previamente por el usuario de Federación Galega de Atletismo. Se tratarán los datos con las
        finalidades de administrar correctamente su presencia en la red social, informando de actividades, productos
        o servicios de Federación Galega de Atletismo. Así como para cualquier otra finalidad que las normativas de
        las redes sociales permitan. En ningún caso se utilizarán los perfiles de seguidores en redes sociales para
        enviar publicidad de manera individual.</li>
</ul>
<p>De acuerdo a lo establecido en el reglamento general de protección de datos europeo
    (RGPD) 2016/679, Federación Galega de Atletismo con domicilio en Avda. Glasgow, 13. 15008 A Coruña será
    responsable del tratamiento de los datos correspondientes a Usuarios de la web y suscriptores.</p>
<p>Federación Galega de Atletismo, no vende, alquila ni cede datos de carácter personal
    que puedan identificar al usuario, ni lo hará en el futuro, a terceros sin el consentimiento previo. Sin
    embargo, en algunos casos se pueden realizar colaboraciones con otros profesionales, en esos casos, se requerirá
    consentimiento a los usuarios informando sobre la identidad del colaborador y la finalidad de la colaboración.
    Siempre se realizará con los más estrictos estándares de seguridad.</p>
<p><strong>Legitimación para el tratamiento de tus datos:</strong></p>
<p>La base legal para el tratamiento de sus datos es: el consentimiento.</p>
<p>Para contactar o realizar comentarios en esta web se requiere el consentimiento con
    esta política de privacidad.</p>
<p>La oferta prospectiva o comercial de productos y servicios está basada en el
    consentimiento que se le solicita, sin que en ningún caso la retirada de este consentimiento condicione la
    ejecución del contrato de suscripción.</p>
<p><strong>Categoría de datos</strong></p>
<p>Las categorías de datos que se tratan son datos identificativos.</p>
<p>No se tratan categorías de datos especialmente protegidos.</p>
<p><strong>Tiempo de conservación de los datos:</strong></p>
<p>Los datos personales proporcionados se conservarán:</p>
<ul>
    <li>Hasta que se solicite su supresión por el
        interesado.</li>
</ul>
<p><strong>Almacenamiento de los datos</strong></p>
<p>Muchas herramientas que se utilizan para gestionar los datos son contratadas a
    terceros.</p>
<p>Para prestar servicios estrictamente necesarios para el desarrollo de la actividad,
    Federación Galega de Atletismo, comparte datos con los siguientes prestadores bajo sus correspondientes
    condiciones de privacidad, cumpliendo con el Reglamento General de Protección de Datos “RGPD”:</p>
<p>Google Analytics: Google LLC, una compañía subsidiaria de Alphabet, Inc con domicilio
    en 1600 Amphitheatre Parkway, Mountain View, CA 94043, Estados Unidos. Google Analytics utiliza “cookies”, que
    son archivos de texto ubicados en tu ordenador, para ayudar a Federación Galega de Atletismo &nbsp;a analizar el
    uso que hacen los usuarios del sitio web. La información que genera la cookie acerca de su uso de
    https://www.carreirasgalegas.com (incluyendo tu dirección IP) será directamente transmitida y archivada por
    Google en sus servidores.</p>
<p>G Suite y Firebase: Google Ireland Limited, con domicilio en <span>Barrow Street, Dublín, 4, Ireland.</p>
<p>Web: Igasoft con domicilio en Calle Pasteur, 20
    2ºIzq. 15008 A Coruña</p>
<p>Windows Azure: Microsoft Ireland Operations Ltd con domicilio en <span>Atrium Building Block B, Carmenhall Road,
        Sandyford Industrial Estate, Dublín, 18,
        Ireland.</p>
<p>Sendgrid: Sendgrid, Inc con domicilio en 1801 California Street, Suite 500 Denver,
    Colorado &nbsp;80202 United States</p>
<p><strong>Navegación</strong></p>
<p>Al navegar por https://www.carreirasgalegas.com se pueden recoger datos no
    identificables, que pueden incluir, direcciones IP, ubicación geográfica (aproximadamente), un registro de cómo
    se utilizan los servicios y sitios, y otros datos que no pueden ser utilizados para identificar al usuario.
    Entre los datos no identificativos están también los relacionados a tus hábitos de navegación a través de
    servicios de terceros. Esta web utiliza los siguientes servicios de análisis de terceros:</p>
<ul>
    <li>Google analytics</li>
</ul>
<p>Se utiliza esta información para analizar tendencias, administrar el sitio, rastrear
    los movimientos de los usuarios alrededor del sitio y para recopilar información demográfica sobre mi base de
    usuarios en su conjunto.</p>
<p><strong>Secreto y seguridad de los datos</strong></p>
<p>Federación Galega de Atletismo se compromete en el uso y tratamiento de los datos
    incluidos personales de los usuarios, respetando su confidencialidad y a utilizarlos de acuerdo con la finalidad
    del mismo, así como a dar cumplimiento a su obligación de guardarlos y adaptar todas las medidas para evitar la
    alteración, pérdida, tratamiento o acceso no autorizado, de conformidad con lo establecido en la normativa
    vigente de protección de datos.</p>
<p>Esta web incluye un certificado SSL. Se trata de un protocolo de seguridad que hace
    que tus datos viajen de manera íntegra y segura, es decir, la transmisión de los datos entre un servidor y
    usuario web, y en retroalimentación, es totalmente cifrada o encriptada.</p>
<p>Federación Galega de Atletismo no puede garantizar la absoluta inexpugnabilidad de la
    red Internet y por tanto la violación de los datos mediante accesos fraudulentos a ellos por parte de
    terceros.</p>
<p>Con respecto a la confidencialidad del procesamiento, Federación Galega de Atletismo
    se asegurará de que cualquier persona que esté autorizada por Federación Galega de Atletismo para procesar los
    datos del cliente (incluido su personal, colaboradores y prestadores), estará bajo la obligación apropiada de
    confidencialidad (ya sea un deber contractual o legal).</p>
<p>Cuando se presente algún incidente de seguridad, al darse cuenta Federación Galega de
    Atletismo, deberá notificar al Cliente sin demoras indebidas y deberá proporcionar información oportuna
    relacionada con el Incidente de Seguridad tal como se conozca o cuando el Cliente lo solicite
    razonablemente.</p>
<p><strong>Exactitud y veracidad de los datos</strong></p>
<p>Como usuario, eres el único responsable de la veracidad y corrección de los datos que
    remitas a Federación Galega de Atletismo exonerando a Federación Galega de Atletismo, de cualquier
    responsabilidad al respecto.</p>
<p>Los usuarios garantizan y responden, en cualquier caso, de la exactitud, vigencia y
    autenticidad de los datos personales facilitados, y se comprometen a mantenerlos debidamente actualizados. El
    usuario acepta proporcionar información completa y correcta en el formulario de contacto o suscripción.
</p>
<p><strong>Aceptación y consentimiento</strong></p>
<p>El usuario declara haber sido informado de las condiciones sobre protección de datos
    de carácter personal, aceptando y consintiendo el tratamiento de los mismos por parte de Federación Galega de
    Atletismo en la forma y para las finalidades indicadas en esta política de privacidad.</p>
<p><strong>Revocabilidad</strong></p>
<p>El consentimiento prestado, tanto para el tratamiento como para la cesión de los datos
    de los interesados, es revocable en cualquier momento comunicándolo a Federación Galega de Atletismo en los
    términos establecidos en esta Política para el ejercicio de los derechos ARCO (<span>acceso, rectificación,
        cancelación y oposición).
        Esta revocación en ningún caso tendrá carácter retroactivo.</p>
<p><strong>Cambios en la política de privacidad</strong></p>
<p>Federación Galega de Atletismo se reserva el derecho a modificar la presente política
    para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria. En dichos
    supuestos, Federación Galega de Atletismo anunciará en esta página los cambios introducidos con razonable
    antelación a su puesta en práctica.</p>
<p><strong>Correos comerciales</strong></p>
<p>De acuerdo con la LSSICE, Federación Galega de Atletismo no realiza prácticas de SPAM,
    por lo que no envía correos comerciales por vía electrónica que no hayan sido previamente solicitados o
    autorizados por el usuario. En consecuencia, en cada uno de los formularios habidos en la web, el usuario tiene
    la posibilidad de dar su consentimiento expreso para recibir el boletín, con independencia de la información
    comercial puntualmente solicitada.</p>
<p>Conforme a lo dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la
    Información y de comercio electrónico, Federación Galega de Atletismo se compromete a no enviar comunicaciones
    de carácter comercial sin identificarlas debidamente.</p>
<p>La presente política de privacidad ha sido actualizado por última vez el
    05-06-2023.</p>
-->
                    <p>A presente declaración de privacidade explica que datos persoais se recollen dos usuarios e como
                        se utilizan.</p>
                    <p>Nesta web respectanse e coidanse os datos persoais dos usuarios. Como usuario debes saber que os
                        teus dereitos están
                        garantidos.</p>
                    <p>Esfórzamonos en crear un espazo seguro e de confianza e por iso queremos compartir os nosos
                        principios respecto á
                        privacidade dos usuarios:</p>
                    <ul>
                        <li>Nunca solicitamos información persoal a non ser que realmente sexa necesaria para
                            proporcionarte os servizos que
                            o usuario requira.</li>
                        <li>Nunca compartimos información persoal dos usuarios con ninguén, excepto para cumprir coa lei
                            ou no caso de que
                            contemos coa túa autorización expresa.</li>
                        <li>Nunca utilizamos os datos persoais dos usuarios cunha finalidade diferente á expresada nesta
                            política de
                            privacidade.</li>
                    </ul>
                    <p>É preciso advertir que esta Política de Privacidade podería variar en función de esixencias
                        lexislativas ou de
                        autorregulación, polo que se aconsella aos usuarios que a visiten periodicamente. Será aplicable
                        no caso de que os
                        usuarios decidan cubrir algún formulario de calquera dos seus formularios de contacto onde se
                        recollen datos de
                        carácter persoal.</p>
                    <p>A Federación Galega de Atletismo adaptou esta web ás esixencias da Lei Orgánica 3/2018, do 5 de
                        decembro, de
                        Protección de Datos Persoais e garantía dos dereitos dixitais (LOPDGDD), e ao Real Decreto
                        1720/2007, do 21 de
                        decembro, coñecido como o Regulamento de desenvolvemento da LOPD. Tamén cumpre co Regulamento
                        (UE) 2016/679 do
                        Parlamento Europeo e do Consello de 27 de abril de 2016 relativo á protección das persoas
                        físicas (RGPD), así como
                        coa Lei 34/2002, do 11 de xullo, de Servizos da Sociedade da Información e Comercio Electrónico
                        (LSSICE ou LSSI).
                    </p>
                    <p>Responsable do tratamento de datos persoais</p>
                    <ul>
                        <li>Identidade do Responsable: Federación Galega de Atletismo</li>
                        <li>NIF/CIF: G15103500</li>
                        <li>Dirección: Avda. Glasgow, 13. 15008 A Coruña</li>
                        <li>Correo electrónico: info@carreirasgalegas.com</li>
                        <li>Actividade: Xestión e organización de actividades relacionadas co atletismo.</li>
                    </ul>
                    <p>Para os efectos do previsto no Regulamento Xeral de Protección de Datos antes citado, os datos
                        persoais que se envíen
                        a través dos formularios da web, recibirán o tratamento de datos de "Usuarios da web e
                        subscritores".</p>
                    <p>Para o tratamento de datos dos usuarios, implementanse todas as medidas técnicas e organizativas
                        de seguridade
                        establecidas na lexislación vixente.</p>
                    <p>No tratamento dos datos persoais, aplicaránse os seguintes principios que se axustan ás
                        esixencias do novo
                        regulamento europeo de protección de datos:</p>
                    <ul>
                        <li>Principio de licitude, lealdade e transparencia: Sempre se requirirá o consentimento do
                            usuario para o
                            tratamento dos seus datos persoais para un ou varios fins específicos que se informarán
                            previamente con absoluta
                            transparencia.</li>
                        <li>Principio de minimización de datos: Só se solicitarán datos estrictamente necesarios en
                            relación cos fins para
                            os que se requiran. Os mínimos posibles.</li>
                        <li>Principio de limitación do prazo de conservación: os datos serán mantidos durante non máis
                            tempo do necesario
                            para os fins do tratamento, en función á finalidade, informarase do prazo de conservación
                            correspondente, no
                            caso de subscricións, periodicamente revisaranse as listas e eliminaranse aqueles rexistros
                            inactivos durante un
                            tempo considerable.</li>
                        <li>Principio de integridade e confidencialidade: Os datos serán tratados de tal maneira que se
                            garantiza unha
                            seguridade adecuada dos datos persoais e se garante confidencialidade. Tomanse todas as
                            precaucións necesarias
                            para evitar o acceso non autorizado ou uso indebido dos datos dos usuarios por parte de
                            terceiros.</li>
                    </ul>
                    <h3>Como obtiven os datos?</h3>
                    <p>Os datos persoais que se tratan na Federación Galega de Atletismo proceden de:</p>
                    <ul>
                        <li>Formulario de contacto</li>
                        <li>Comunicacións directas cos usuarios</li>
                    </ul>
                    <p><strong>Dereitos sobre os datos:</strong></p>
                    <p>Cualquiera persoa ten dereito a obter confirmación sobre se na Federación Galega de Atletismo
                        están a tratar datos
                        persoais que lle incumben, ou non.</p>
                    <p>As persoas interesadas teñen dereito a:</p>
                    <ul>
                        <li>Solicitar o acceso aos datos persoais relativos ao interesado</li>
                        <li>Solicitar a súa rectificación ou supresión</li>
                        <li>Solicitar a limitación do seu tratamento</li>
                        <li>Opoñerse ao tratamento</li>
                        <li>Solicitar a portabilidade dos datos</li>
                    </ul>
                    <p>Os interesados poderán acceder aos seus datos persoais, así como a solicitar a rectificación dos
                        datos inexactos ou,
                        no seu caso, solicitar a súa supresión cando, entre outros motivos, os datos xa non sexan
                        necesarios para os fins
                        que foron recollidos. En determinadas circunstancias, os interesados poderán solicitar a
                        limitación do tratamento
                        dos seus datos, neste caso, só se conservarán para o exercicio ou a defensa de reclamacións.</p>
                    <p>En determinadas circunstancias e por motivos relacionados coa súa situación particular, os
                        interesados poderán
                        opoñerse ao tratamento dos seus datos. A Federación Galega de Atletismo deixará de tratar os
                        datos, agás por motivos
                        lexítimos imperiosos, ou o exercicio ou a defensa de posibles reclamacións. Como interesado, o
                        usuario ten dereito a
                        recibir os datos persoais que lle incumban, que teña facilitado a un responsable, nun formato
                        estruturado, de uso
                        común e lectura mecánica, e a transmitilos a outro responsable do tratamento cando:</p>
                    <ul>
                        <li>O tratamento estea baseado no consentimento</li>
                        <li>Os datos teñan sido facilitados pola persoa interesada.</li>
                        <li>O tratamento se efectúe por medios automatizados.</li>
                    </ul>
                    <p>Ao exercer o seu dereito á portabilidade dos datos, o usuario terá dereito a que os datos
                        persoais se transmitan
                        directamente de responsable a responsable cando sexa tecnicamente posible.</p>
                    <p>Os interesados tamén terán dereito a unha tutela xudicial efectiva e a presentar unha reclamación
                        ante a autoridade
                        de control, neste caso, a Axencia Española de Protección de Datos, se consideran que o
                        tratamento de datos persoais
                        que lles concirne infrinxe o Regulamento.</p>
                    <p><strong>Finalidade dos datos persoais:</strong></p>
                    <p>Cando un usuario se conecta con esta web por exemplo para enviar un correo ao titular,
                        suscribirse ou realizar
                        algunha contratación, está facilitando información de carácter persoal da que é responsable a
                        Federación Galega de
                        Atletismo. Esa información pode incluír datos de carácter persoal como poden ser: dirección IP,
                        nome, dirección
                        física, dirección de correo electrónico, número de teléfono, e outra información. Ao facilitar
                        esta información, o
                        usuario da o seu consentimento para que a súa información sexa recopilada, utilizada, xestionada
                        e almacenada por
                        Federación Galega de Atletismo, só como se describe no Aviso Legal e nesta Política de
                        Privacidade.</p>
                    <p>Existen outras finalidades polas que se tratan os datos persoais:</p>
                    <ul>
                        <li>Para garantir o cumprimento das condicións de uso e a lei aplicable. Isto pode incluír o
                            desenvolvemento de
                            ferramentas e algoritmos que axuden a esta web a garantir a confidencialidade dos datos
                            persoais que recolle.
                        </li>
                        <li>Para apoiar e mellorar os servizos que ofrece esta web.</li>
                        <li>Tamén se recollen outros datos non identificativos que se obteñen mediante algunhas cookies
                            que se descargan no
                            ordenador do usuario cando navega nesta web que detallo na política de cookies.</li>
                        <li>Para xestionar as redes sociais. A Federación Galega de Atletismo pode ter presenza en redes
                            sociais. O
                            tratamento dos datos que se leve a cabo das persoas que se fagan seguidoras nas redes
                            sociais das páxinas
                            oficiais de https://www.carreirasgalegas.com, rexirase por este apartado. Así como por
                            aquelas condicións de
                            uso, políticas de privacidade e normativas de acceso que pertenzan á rede social que proceda
                            en cada caso e
                            aceptadas previamente polo usuario de Federación Galega de Atletismo. Trataranse os datos
                            coas finalidades de
                            administrar correctamente a súa presenza na rede social, informando de actividades, produtos
                            ou servizos da
                            Federación Galega de Atletismo. Así como para calquera outra finalidade que as normativas
                            das redes sociais
                            permitan. En ningún caso utilizaranse os perfís de seguidores en redes sociais para enviar
                            publicidade de
                            maneira individual.</li>
                    </ul>
                    <p>De acordo co establecido no Regulamento Xeral de Protección de Datos Europeo (RGPD) 2016/679, a
                        Federación Galega de
                        Atletismo, con domicilio en Avda. Glasgow, 13. 15008 A Coruña, será responsable do tratamento
                        dos datos
                        correspondentes aos Usuarios da web e aos subscritores.</p>
                    <p>A Federación Galega de Atletismo, non vende, aluga nin cede datos de carácter persoal que poidan
                        identificar ao
                        usuario, nin o fará no futuro, a terceiros sen o consentimento previo. Porén, en algúns casos
                        pódense realizar
                        colaboracións con outros profesionais, neses casos, requirirase consentimento aos usuarios
                        informando sobre a
                        identidade do colaborador e a finalidade da colaboración. Sempre se realizará cos máis estritos
                        estándares de
                        seguridade.</p>
                    <p><strong>Lexitimación para o tratamento dos teus datos:</strong></p>
                    <p>A base legal para o tratamento dos teus datos é: o consentimento.</p>
                    <p>Para contactar ou realizar comentarios nesta web requírese o consentimento con esta política de
                        privacidade.</p>
                    <p>A oferta prospectiva ou comercial de produtos e servizos está baseada no consentimento que se
                        solicita, sen que en
                        ningún caso a retirada deste consentimento condicione a execución do contrato de subscrición.
                    </p>
                    <p><strong>Categoría de datos</strong></p>
                    <p>As categorías de datos que se tratan son datos identificativos.</p>
                    <p>Non se tratan categorías de datos especialmente protexidos.</p>
                    <p><strong>Tempo de conservación dos datos:</strong></p>
                    <p>Os datos persoais proporcionados conservaranse:</p>
                    <ul>
                        <li>Ata que non se solicite a súa supresión polo interesado.</li>
                    </ul>
                    <p><strong>Almacenamento dos datos</strong></p>
                    <p>Moitas ferramentas que se utilizan para xestionar os datos son contratadas a terceiros.</p>
                    <p>Para prestar servizos estritamente necesarios para o desenvolvemento da actividade, Federación
                        Galega de Atletismo,
                        comparte datos cos seguintes provedores baixo as súas correspondentes condicións de privacidade,
                        cumprindo co
                        Regulamento Xeral de Protección de Datos “RGPD”:</p>
                    <p>Google Analytics: Google LLC, unha compañía subsidiaria de Alphabet, Inc con domicilio en 1600
                        Amphitheatre Parkway,
                        Mountain View, CA 94043, Estados Unidos. Google Analytics utiliza “cookies”, que son arquivos de
                        texto ubicados no
                        teu ordenador, para axudar a Federación Galega de Atletismo &nbsp;a analizar o uso que fan os
                        usuarios do sitio web.
                        A información que xera a cookie acerca do seu uso de https://www.carreirasgalegas.com (incluíndo
                        a túa dirección IP)
                        será directamente transmitida e almacenada por Google nos seus servidores.</p>
                    <p>G Suite e Firebase: Google Ireland Limited, con domicilio en Barrow Street, Dublín, 4, Ireland.
                    </p>
                    <p>Web: Igasoft con domicilio en Calle Pasteur, 20 2ºIzq. 15008 A Coruña</p>
                    <p>Windows Azure: Microsoft Ireland Operations Ltd con domicilio en Atrium Building Block B,
                        Carmenhall Road, Sandyford
                        Industrial Estate, Dublín, 18, Ireland.</p>
                    <p>Sendgrid: Sendgrid, Inc con domicilio en 1801 California Street, Suite 500 Denver, Colorado
                        &nbsp;80202 United States
                    </p>
                    <p><strong>Navegación</strong></p>
                    <p>Ao navegar por https://www.carreirasgalegas.com pódense recoller datos non identificables, que
                        poidan incluír,
                        direccións IP, ubicación xeográfica (aproximadamente), un rexistro de como se utilizan os
                        servizos e sitios, e
                        outros datos que non poidan ser utilizados para identificar ao usuario. Entre os datos non
                        identificativos están
                        tamén os relacionados aos teus hábitos de navegación a través de servizos de terceiros. Esta web
                        utiliza os
                        seguintes servizos de análise de terceiros:</p>
                    <ul>
                        <li>Google analytics</li>
                    </ul>
                    <p>Utilízase esta información para analizar tendencias, administrar o sitio, rastrear os movementos
                        dos usuarios ao
                        redor do sitio e para recopilar información demográfica sobre a miña base de usuarios no seu
                        conxunto.</p>
                    <p><strong>Segredo e seguridade dos datos</strong></p>
                    <p>A Federación Galega de Atletismo comprométese no uso e tratamento dos datos incluídos persoais
                        dos usuarios,
                        respectando a súa confidencialidade e a utilizalos de acordo coa finalidade do mesmo, así como a
                        cumprir a súa
                        obrigación de gardalos e adaptar todas as medidas para evitar a alteración, perda, tratamento ou
                        acceso non
                        autorizado, de conformidade co establecido na normativa vixente de protección de datos.</p>
                    <p>Esta web inclúe un certificado SSL. Trátase dun protocolo de seguridade que fai que os teus datos
                        viaxen de maneira
                        íntegra e segura, é dicir, a transmisión dos datos entre un servidor e usuario web, e en
                        retroalimentación, é
                        totalmente cifrada ou encriptada.</p>
                    <p>A Federación Galega de Atletismo non pode garantir a absoluta inexpugnabilidade da rede Internet
                        e por tanto a
                        violación dos datos mediante accesos fraudulentos a eles por parte de terceiros.</p>
                    <p>Con respecto á confidencialidade do procesamento, a Federación Galega de Atletismo asegurará de
                        que calquera persoa
                        que estea autorizada por Federación Galega de Atletismo para procesar os datos do cliente
                        (incluído o seu persoal,
                        colaboradores e prestadores), estará baixo a obriga adecuada de confidencialidade (xa sexa un
                        deber contractual ou
                        legal).</p>
                    <p>Cando se presente algún incidente de seguridade, ao darse conta a Federación Galega de Atletismo,
                        deberá notificar ao
                        Cliente sen demoras indebidas e deberá proporcionar información oportuna relacionada co
                        Incidente de Seguridade tal
                        como se coñeza ou cando o Cliente o solicite razoablemente.</p>
                    <p><strong>Exactitude e veracidade dos datos</strong></p>
                    <p>Como usuario, eres o único responsable da veracidade e corrección dos datos que remitas a
                        Federación Galega de
                        Atletismo exonerando a Federación Galega de Atletismo, de calquera responsabilidade ao respecto.
                    </p>
                    <p>Os usuarios garanten e responden, en calquera caso, da exactitude, vixencia e autenticidade dos
                        datos persoais
                        facilitados, e comprométense a mantelos debidamente actualizados. O usuario acepta proporcionar
                        información completa
                        e correcta no formulario de contacto ou suscripción.
                    </p>
                    <p><strong>Aceptación e consentimento</strong></p>
                    <p>O usuario declara ter sido informado das condicións sobre protección de datos de carácter
                        persoal, aceptando e
                        consentindo o tratamento dos mesmos por parte da Federación Galega de Atletismo na forma e para
                        as finalidades
                        indicadas nesta política de privacidade.</p>
                    <p><strong>Revogabilidade</strong></p>
                    <p>O consentimento prestado, tanto para o tratamento como para a cesión dos datos dos interesados, é
                        revogable en
                        calquera momento comunicándoo a Federación Galega de Atletismo nos termos establecidos nesta
                        Política para o
                        exercicio dos dereitos ARCO (acceso, rectificación, cancelación e oposición).
                        Esta revogación en ningún caso terá carácter retroactivo.</p>
                    <p><strong>Cambios na política de privacidade</strong></p>
                    <p>A Federación Galega de Atletismo resérvase o dereito a modificar a presente política para
                        adaptala a novidades
                        lexislativas ou xurisprudenciais, así como a prácticas da industria. Nestes supostos, a
                        Federación Galega de
                        Atletismo anunciará nesta páxina os cambios introducidos con razoable antelación á súa posta en
                        práctica.</p>
                    <p><strong>Correos comerciais</strong></p>
                    <p>De acordo coa LSSICE, a Federación Galega de Atletismo non realiza prácticas de SPAM, polo que
                        non envía correos
                        comerciais por vía electrónica que non fosen previamente solicitados ou autorizados polo
                        usuario. En consecuencia,
                        en cada un dos formularios habidos na web, o usuario ten a posibilidade de dar o seu
                        consentimento expreso para
                        recibir o boletín, con independencia da información comercial puntualmente solicitada.</p>
                    <p>Conforme ao disposto na Lei 34/2002 de Servizos da Sociedade da Información e de comercio
                        electrónico, a Federación
                        Galega de Atletismo comprométese a non enviar comunicacións de carácter comercial sen
                        identificalas debidamente.</p>
                    <p>A presente política de privacidade foi actualizada por última vez o 05-06-2023.</p>
                </div>
                <div class="mb-4 text-center"><img src="assets/img/cg-separator.png" alt=""></div>
            </div>
        </div>
    </div>
</section>