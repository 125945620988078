import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {


  year = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }
  public isScrolledDown = false; // Esta variable booleana indicará si la página ha sido desplazada hacia abajo más de 500 píxeles

  @HostListener('window:scroll', [])// Esto indica que se debe escuchar el evento de desplazamiento de la ventana
  onWindowScroll() {
    this.isScrolledDown = (document.documentElement.scrollTop > 500);// Si el desplazamiento vertical de la página es mayor de 500 píxeles, se establece la variable 
  }

  public scrollToTop() {// Esta función se utilizará para desplazar la página hacia la parte superior de forma suave
    window.scrollTo({// Se llama a la función scrollTo del objeto window para desplazar la página hacia la parte superior de forma suave
      top: 0,
      behavior: 'smooth'
    });
  }
}
