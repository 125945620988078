import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LazyLoadEvent } from "primeng/api";
import { Observable } from "rxjs";
import { HttpParamsBuilder } from "src/app/shared/http-params.builder";
import { PaginatedResult } from "src/app/shared/paginated-result";
import { environment } from "src/environments/environment";
import { ClubModel } from "../models/club";
import { ConfigurationModel } from "../models/configuration";

@Injectable()
export class ConfigurationsService {

    constructor(private http: HttpClient) { }

    private buildUrl(id?: string): string {
        if (id)
            return `${environment.api}/configurations/${id}`
        return `${environment.api}/configurations`
    }

    getAll(event: LazyLoadEvent): Observable<ConfigurationModel[]> {
        let params = HttpParamsBuilder.fromLazyEvent(event);
        return this.http.get<ConfigurationModel[]>(
            this.buildUrl(),
            {
                params: params
            }
        )
    }
}