import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable()
export class ToastNotifierService {
    constructor(private messageService: MessageService) { }

    public fail(message: string) {
        this.messageService.add({ key: 'bc', severity: 'error', summary: 'Mensaxe de error', detail: message });
    }

    public success(message: string) {
        this.messageService.add({ key: 'bc', severity: 'success', summary: 'Mensaxe de éxito', detail: message });
    }
}
