<header #header class="marathon-header-fixed header-fixed">
  <div class="header-nav">
    <div class="header-profile">
      <div class="container">
        <div class="row">
          <div class="d-none d-sm-block col-sm-2 col-md-5 col-lg-5">
            <ul class="list-inline social-list">
              <li class="d-none d-md-inline list-inline-item"><i class="fa-regular fa-envelope"></i>
                info@carreirasgalegas.com</li>
              <li class="d-none d-lg-inline list-inline-item ms-2"><i class="fa-solid fa-phone"></i> +34 981 291 683
              </li>
              <li class="list-inline-item ms-2"><a target="_blank" href="https://www.facebook.com/carreirasgalegas"><i
                    class="fab fa-facebook-f"></i></a></li>
              <li class="list-inline-item"><a target="_blank" href="https://twitter.com/carreirasgal"><i
                    class="fab fa-twitter"></i></a></li>
              <li class="list-inline-item"><a target="_blank" href="https://www.instagram.com/carreirasgalegas"><i
                    class="fab fa-instagram"></i></a></li>
              <li class="list-inline-item"><a target="_blank" href="https://www.youtube.com/@carreirasgal"><i
                    class="fab fa-youtube"></i></a></li>
            </ul>
          </div>
          <div class="col col-sm-10 col-md-7 col-lg-7 text-sm-end">
            <ul class="list-inline profile-list">
              <ng-container *ngIf="user && !user.isAnonymous">
                <li class="list-inline-item">
                  <!--<img class="user-avatar align-middle" src="assets/img/profile.png" alt="profile"><i class="fa-solid fa-chevron-down"></i>-->

                  <a routerLink="runner/details">{{displayName}}</a>
                </li>
                <li class="d-none d-lg-inline list-inline-item ms-3" *ngIf="displayName"><i
                    class="fa-regular fa-clock"></i>
                  <a routerLink="runner/results">Resultados</a>
                </li>
                <li class="d-none d-lg-inline list-inline-item ms-3" *ngIf="displayName"><i
                    class="fa-solid fa-person-running"></i>
                  <a routerLink="runner/enrollments">Carreiras</a>
                </li>
                <li class="list-inline-item ms-3">
                  <a class="button tiny log-button" (click)="logout()">Saír
                    <div class="button-ornament">
                      <i class="fa-solid fa-chevron-right arrow-icon"></i>
                    </div>
                  </a>
                </li>
              </ng-container>
              <li *ngIf="!user || user.isAnonymous" class="list-inline-item ms-3">
                <a routerLink="auth/login" class="button tiny log-button">
                  Entrar
                  <div class="button-ornament">
                    <i class="fa-solid fa-chevron-right arrow-icon"></i>
                  </div>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid main-menu" [class.active]="isNavActive">
      <a class="nav-btn" (click)="toggleNav()">
        <span></span>
        <span></span>
        <span></span>
      </a>
      <div class="container">
        <div class="header-nav-cover">
          <a routerLink="" class="d-flex align-items-center my-2 my-md-0 me-md-auto text-dark text-decoration-none">
            <img src="assets/img/logo-carreiras-galegas.png" alt="logo carreiras galegas">
          </a>
          <nav class="nav-menu menu" [ngClass]="{'active': isNavOpen}">
            <ul class="nav-list">
              <li [class.active-page]="activeLink === '/events'" class="dropdown">
                <a routerLink="/events" (click)="closeNav()">Calendario de carreiras</a>
              </li>
              <li [class.active-page]="activeLink === '/results'">
                <a routerLink="/results" (click)="closeNav()">Resultados</a>
              </li>
              <li [class.active-page]="activeLink === '/news'">
                <a routerLink="/news" (click)="closeNav()">Novas</a>
              </li>
              <li [class.active-page]="activeLink === '/circuits'">
                <a routerLink="/circuits" (click)="closeNav()">Circuítos</a>
              </li>
              <li [class.active-page]="activeLink === '/contact'">
                <a routerLink="/contact" (click)="closeNav()">Contacto</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>