import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-payment-nok',
    templateUrl: './payment-nok.component.html'
})
export class PaymentNokComponent implements OnInit {

    constructor(private router: Router,) { }
    ngOnInit() {
    }


}