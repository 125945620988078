<footer>
    <div class="container">
        <div class="row">
            <div class="footer-cont col-12 col-lg-6 text-center text-xl-start">
                <a href="index.html" class="logo"><img src="assets/img/logo-footer.png" alt="logo"></a>
                <p>Avenida de Glasgow S/N <br />O Birloque, 15008 A Coruña</p>
                <ul class="footer-contacts">
                    <li class="footer-phone">
                        <i aria-hidden="true" class="fas fa-phone"></i>
                        <a href="tel:+18001234567">+34 981 291 683</a>
                    </li>
                    <li class="footer-email">
                        <a href="mailto:info@carreirasgalegas.com">info@carreirasgalegas.com</a>
                    </li>
                </ul>
                <div class="footer-copyright">© {{year}} <a target="_blank" href="https://atletismo.gal/">Federación
                        Galega
                        de Atletismo</a>
                    - <a routerLink="privacy-policy">Política de privacidade</a>
                    - <a routerLink="cookies-policy">Política de cookies</a>
                    - <a routerLink="legal-advice">Aviso legal</a>
                </div>
            </div>
            <div class="footer-item-link col-12 col-lg-3 text-center text-xl-start">
                <div class="footer-link">
                    <h5>Info</h5>
                    <ul class="footer-list">
                        <li><a routerLink="/contact">Contacto</a></li>
                        <li><a routerLink="/events">Calendario</a></li>
                        <li><a routerLink="/results">Resultados</a></li>
                        <li><a title="Licenzas" href="https://licenzas.carreirasgalegas.com"
                                target="_blank">Licenzas</a></li>
                    </ul>
                </div>
            </div>
            <!--<div class="footer-subscribe col-12 col-lg-5 text-center text-xl-start">
                <h5>Suscríbete a nosa newsletter e mantente ao día das vindeiras carreiras populares</h5>
                <form class="subscribe-form">
                    <input class="inp-form" type="email" name="subscribe" placeholder="E-mail">
                    <button class="btn-form" type="submit"><i class="fas fa-paper-plane"></i></button>
                </form>
                <p>Ao pulsar no botón de envío aceptas a nosa <a href="#" target="_blank">Política de Privacidade</a> e
                    <a href="#" target="_blank">Termos e Condicións</a>
                </p>
            </div>-->
        </div>
    </div>
</footer>
<a class="to-top" href="#home" [ngClass]="{'active': isScrolledDown}">
    <i class="fa fa-angle-double-up" aria-hidden="true"></i>
</a>